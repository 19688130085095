/*----------------------------------------------------
@File: Default Styles
@Author: Dreemsthemes
@URL: http://wethemez.com

This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.
---------------------------------------------------- */
/*=====================================================================
@Template Name: Cryptogold
@Author: Dreemsthemes
@Developed By: Rony
@Developer URL: http://rocky.obaidul.com

@Default Styles

Table of Content:
01/ Variables
02/ predefin
03/ header
04/ slider
05/ photography
06/ button 
07/ work 
08/ team
09/ contact 
10/ price 
11/ portfolio 
12/ blog 
13/ sidebar  
13/ page-header  
13/ project  
13/ we-dope  
13/ progress
13/ comingsoon
13/ error
13/ footer
=====================================================================*/
/*----------------------------------------------------*/
/*font Variables*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Open+Sans|Lora:400i");
.col-md-offset-right-1 {
  margin-right: 8.33333333%;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.list_style {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.row.m0 {
  padding: 0px;
  margin: 0px;
}

body {
  line-height: 30px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: #797979;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
}

.mt_40 {
  margin-top: 40px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_80 {
  margin-top: 80px;
}

.mb_70 {
  margin-bottom: 70px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_80 {
  margin-bottom: 80px;
}

.t_color {
  color: #040c2c;
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

.row_reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.bottom_line {
  width: 80px;
  height: 2px;
  margin: 25px auto 0px;
  display: inline-block;
  background: #bcbcbc;
}

.title_h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
}

.title_p {
  font-size:16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0px;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}

/* Main title css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============ theme_btn css =========*/
.theme_btn {
  font-size: 20px;
  font-weight: 500;
  color: #040c2c;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #040c2c;
  padding: 13px 36px;
  border-radius: 3px;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 1;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  cursor: pointer;
  min-width: 140px;
  text-align: center;
}

.theme_btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  border-radius: 1px;
  background: #040c2c;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.theme_btn:hover {
  color: #fff;
}

.theme_btn:hover:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*========= header_area css ===========*/
.navbar {
  width: 100%;
  top: 0px;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  z-index: 1001;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibilite: hidden;
}

@media (min-width: 1300px) {
  .navbar .custome_container {
    max-width: 1440px;
  }
}

.navbar .logo_h {
  margin-right: 0px;
}

.navbar .logo_h img {
  max-width: 100%;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.navbar .logo_h img + img {
  display: none;
}

.navbar .navbar-nav .nav-item {
  margin-left: 35px;
}

.navbar .navbar-nav .nav-item .nav-link {
  font: 600 14px/30px "Montserrat", sans-serif;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding: 0px;
  cursor: pointer;
}

.navbar .navbar-nav .nav-item .nav-link:before {
  content: "";
  background: #fff;
  opacity: 0.71;
  position: absolute;
  left: auto;
  right: 0px;
  bottom: 0px;
  width: 0%;
  height: 1.3px;
  z-index: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}

.navbar .navbar-nav .nav-item .nav-link.active:before, .navbar .navbar-nav .nav-item .nav-link:hover:before {
  width: 100%;
  left: 0;
}

.navbar .navbar-nav.nav-right .nav-item {
  margin-left: 0px;
}

.navbar .login {
  padding: 5px 34px;
  font: 600 14px/30px "Montserrat", sans-serif;
  background: #fff;
  border-radius: 3px;
  color: #494949;
  text-transform: uppercase;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
  display: inline-block;
  border: 2px solid transparent;
  overflow: hidden;
  z-index: 1;
  position: relative;
  margin-top: 7px;
}

.navbar .login:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  border-radius: 0px;
  background: #fff;;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.navbar .login:hover {
  -webkit-box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.12);
  color: #040c2c;
}

.navbar .login:hover:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.navbar_fixed .navbar{
  background: #fff;
  -webkit-box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.1);
  top: 0;
}

.navbar_fixed .logo_h img {
  display: none;
}

.navbar_fixed .logo_h img + img {
  display: inline-block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.navbar_fixed .navbar .navbar-nav .nav-item .nav-link {
  color: #040c2c;
}

.navbar_fixed .navbar-nav .nav-item .nav-link:before {
  background: #040c2c;
}

.navbar_fixed .login {
  border-color: #040c2c;
  color: #040c2c;
}
.navbar_fixed .login:before{
  background: #040c2c;
}

.navbar_fixed .login:hover {
  color: #fff;
}

.navbar-toggler {
  border: 0px;
  padding: 0px;
  border-radius: 0px;
  height: 80px;
  cursor: pointer;
}

.navbar-toggler span {
  display: block;
  width: 30px;
  height: 2px;
  background-image: -moz-linear-gradient(0deg, #ed3d82 0%, #f66264 75%, #fe8645 100%);
  background-image: -webkit-linear-gradient(0deg, #ed3d82 0%, #f66264 75%, #fe8645 100%);
  background-image: -ms-linear-gradient(0deg, #ed3d82 0%, #f66264 75%, #fe8645 100%);
  margin: auto;
  margin-bottom: 5px;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.navbar-toggler[aria-expanded="true"] span:first-child {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  top: 6px;
}

.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"] span:nth-child(3) {
  -webkit-transform: rotate(42deg);
  -ms-transform: rotate(42deg);
  transform: rotate(42deg);
  bottom: 8px;
  position: relative;
}

.navbar-toggler:focus {
  outline: none;
}

.dark_menu .navbar-nav .nav-item .nav-link{
  color: #040c2c;
}
.dark_menu .navbar-nav .nav-item .nav-link.active:before, .dark_menu .navbar .navbar-nav .nav-item .nav-link:hover:before{
  background: #040c2c;
}
.dark_menu .login{
  background: #040c2c;
  color: #fff;
}
.dark_menu .logo_h img{
  display: none;
}
.dark_menu .logo_h img + img{
  display: block;
}
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*========== banner_area css =========*/
.banner_area,.banner_area_two {
  min-height: 100vh;
  position: relative;
  z-index: 1;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
.react-parallax-bgimage{
  height: 100vh;
  top: 0;
  z-index: -1;
  width: 100%;
  left: 0;
}
.react-parallax-content{
  width: 100%;
}
.banner_area:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("../../image/grid-70.png") no-repeat scroll center 0/cover;
  z-index: 0;
}
.particle{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.banner_content {
  color: #fff;
  position: relative;
}

.banner_content h5 {
  font-size: 20px;
  margin: 0;
  font-weight: 400;
  margin-bottom: 32px;
  letter-spacing: 1px;
}

.banner_content h2 {
  margin: 10px 0px;
  font-size: 45px;
  font-weight: 700;
}

.banner_content h4 {
  font-size: 22px;
  letter-spacing: 0px;
  padding: 10px 0px 30px;
}
.banner_content h4 span{
  font-weight: 700;
}

.banner_content .social_icon li {
  display: inline-block;
  margin: 0px 3px;
}

.banner_content .social_icon li a {
  font-size: 14px;
  width: 42px;
  height: 42px;
  line-height: 43px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  display: inline-block;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.banner_content .social_icon li a:hover {
  background: #fff;
  color: #040c2c;
}

.banner_area_two .banner_content{
  color: #040c2c;
  position: relative;
  z-index: 3;
}
.banner_area_two .banner_content h2{
  font-size: 75px;
}
.banner_area_two .banner_content h3{
  font-size: 24px;
  padding-top: 20px;
  margin-bottom: 2px;
}
.banner_area_two .banner_content .social_icon li a{
  background: #040c2c;
}
.banner_area_two .banner_content .social_icon li a:hover{
  box-shadow: 0px 2px 35px 0px rgba(4, 12, 44, 0.5);
  color: #fff;
  transform: translateY(-4px);
}
.banner_area_two .one_img{
  position: absolute;
  top: -15px;
  left: -150px;
  opacity: 0.6;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite
}
@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}

@keyframes spin {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}
/*========== banner_area css =========*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== about_area css ===========*/
.about_area {
  overflow: hidden;
  padding-top: 80px;
}

.about_content {
  padding-right: 88px;
  padding-bottom: 50px;
}

.about_content h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
}

.about_content h6 {
  color: #999;
  letter-spacing: 1px;
  margin-bottom: 30px;
  font-size: 16px;
}

.about_content p {
  padding-bottom: 30px;
}
.about_content .theme_btn.active{
  color: #fff;
}
.about_content .theme_btn.active:before{
  transform: scale(1);
}
.about_content .theme_btn + .theme_btn {
  margin-left: 15px;
}
.about_content .theme_btn.active:hover{
  color: #040c2c;
}
.about_content .theme_btn.active:hover:before{
  transform: scaleX(0);
}
.about_img img {
  margin-left: -50px;
}

.work_area {
  background: #fafafa;
  padding: 110px 0px 90px;
}

.work_item {
  text-align: center;
  border: 1px solid #f3f1f1;
  background: #fff;
  padding: 40px 25px 30px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}

.work_item i {
  border: 1px solid #8a8a8a;
  border-radius: 50%;
  color: #8a8a8a;
  font-size: 32px;
  height: 80px;
  line-height: 80px;
  margin-bottom: 20px;
  width: 80px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  display: inline-block;
}

.work_item h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  padding-bottom: 5px;
}

.work_item:hover {
  -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
}

/*================= about_area_two css ==============*/
.about_area_two {
  padding: 120px 0px;
}

.n_section_title {
  margin-bottom: 60px;
}

.n_section_title .top_border {
  height: 40px;
  width: 1px;
  background: #cccccc;
  margin: 0 auto;
  display: block;
}

.n_section_title h6 {
  font-size: 14px;
  color: #ff214f;
  margin: 30px 0px 10px;
}

.n_section_title h2 {
  font: 300 32px/40px "Montserrat", sans-serif;
  color: #232323;
}

.n_section_title h2 span {
  font-weight: 500;
}

.about_item {
  padding-left: 55px;
  position: relative;
}

.about_item i {
  font-size: 26px;
  position: absolute;
  left: 0;
  top: 5px;
}

.about_item h3 {
  font: 600 14px/24px "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #040c2c;
}

.about_item p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0px;
}

.promo_section .padding {
  padding: 0px;
}

.promo_section .promo_box_one {
  background: #000;
  padding: 0px 60px;
}

.promo_section .promo_box_one .content {
  position: relative;
}

.promo_section .promo_box_one .content i {
  font-size: 80px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  color: #fff;
  position: absolute;
  top: 0;
  left: -18px;
  color: #ff214f;
}

.promo_section .promo_box_one .content p {
  font: 600 28px/36px "Montserrat", sans-serif;
  color: #fff;
  padding-top: 80px;
  margin-bottom: 0px;
}

.promo_section .promo_video {
  position: relative;
  z-index: 1;
  text-align: center;
}

.promo_section .promo_video:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 0;
}

.promo_section .promo_video img {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.promo_section .promo_video .video_btn {
  position: absolute;
  top: 50%;
  font-size: 22px;
  line-height: normal;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #fff;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.promo_section .promo_video .video_btn i {
  padding-right: 10px;
}

.promo_section .promo_skill {
  background: #f7f7f7;
  padding: 40px 60px;
}

.promo_section .promo_skill h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: normal;
  color: #040c2c;
  margin-bottom: 25px;
}

.promo_section .promo_skill .progress_bar_info {
  width: 100%;
}

.promo_section .promo_skill .progress_bar_info .progress {
  height: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #d0dae3;
  overflow: visible;
  margin-bottom: 20px;
  position: relative;
}

.promo_section .promo_skill .progress_bar_info .progress .progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #F24259;
  width: 10%;
  border-radius: 10px;
  -webkit-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
}

.promo_section .promo_skill .progress_bar_info .progress .count-tip {
  position: absolute;
  top: -46px;
  right: 0;
  color: #797979;
  font-size: 14px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== portfolio_area css ============*/
.portfolio_area {
  padding: 110px 0px 90px;
}

.portfolio_menu {
  margin-bottom: 50px;
}

.portfolio_menu li {
  display: inline-block;
  background-color: transparent;
  border: 1px solid #ededed;
  border-radius: 2px;
  color: #494949;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 38px;
  margin-right: 18px;
  padding: 0 25px;
  text-transform: capitalize;
  -webkit-transition: background 0.5s ease-in-out, color 0.2s linear;
  -o-transition: background 0.5s ease-in-out, color 0.2s linear;
  transition: background 0.5s ease-in-out, color 0.2s linear;
  cursor: pointer;
}

.portfolio_menu li:hover, .portfolio_menu li.active {
  background: #040c2c;
  color: #fff;
}

.grid-item {
  margin-bottom: 30px;
}

.portfolio {
  position: relative;
}

.portfolio img {
  max-width: 100%;
}

.portfolio .item-img-overlay {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  background: rgba(255, 255, 255, 0.95);
  opacity: 0;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  z-index: 2;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  cursor: pointer;
}

.portfolio .item-img-overlay .overlay-info {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.portfolio .item-img-overlay .overlay-info h6 {
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  position: relative;
  margin-bottom: 15px;
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.portfolio .item-img-overlay .overlay-info .icons a {
  display: inline-block;
  width: 45px;
  height: 45px;
  font-size: 16px;
  line-height: 43px;
  border-radius: 50%;
  border: 1px solid #111;
  background: #111;
  color: #eee;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin: 0px 5px;
}

.portfolio .item-img-overlay .overlay-info .icons a:hover {
  background: #fff;
  color: #040c2c;
}

.portfolio:hover .item-img-overlay {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.portfolio:hover .overlay-info h6 {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

/*================== project_count_area css ================*/
.project_count_area {
  background: #fafafa;
  padding: 120px 0px;
}

.project_count_area .counter_item i {
  font-size: 35px;
  margin-bottom: 25px;
}

.project_count_area .counter_item .t_color {
  font: 600 35px/40px "Montserrat", sans-serif;
}

.project_count_area .counter_item p {
  margin-bottom: 0px;
  font-size: 16px;
}

.p_count_two {
  background: #fff;
  padding: 80px 0px;
}

.p_count_two .counter_item i {
  color: #ff214f;
}

.bg_two {
  background: #fafafa;
}

/*=============== testimonial_area css  ==============*/
.testimonial_area {
  background: url("/image/portfolio/img-3.jpg") no-repeat scroll center 0/cover;
  position: relative;
  z-index: 1;
  padding: 120px 0px;
}

.testimonial_area:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 0;
  width: 100%;
  height: 100%;
}

.testimonial_area .testimonial_slider {
  text-align: center;
  padding: 0px 100px;
}

.testimonial_area .testimonial_slider .item {
  color: #fff;
}

.testimonial_area .testimonial_slider .item .author_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  border: 1px solid #ededed;
  background: #fff;
}

.testimonial_area .testimonial_slider .item .author_img img {
  max-width: 100%;
  width: auto;
}

.testimonial_area .testimonial_slider .item h6 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 15px 0 0px;
}

.testimonial_area .testimonial_slider .item span {
  font: 400 13px "Open Sans", sans-serif;
  opacity: 0.6;
}

.testimonial_area .testimonial_slider .item p {
  margin-bottom: 0px;
  margin-top: 15px;
}
.testimonial_area .testimonial_slider .slick-dots {
  left: 0;
  position: relative;
}
.testimonial_area .testimonial_slider .slick-dots li{
  margin: 0px;
}
.testimonial_area .testimonial_slider .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #fff;
  opacity: 0.50;
  margin: 0px 0px 0px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.testimonial_area .testimonial_slider .slick-dots li button:before{
  display: none;
}

.testimonial_area .testimonial_slider .slick-dots .slick-active button{
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 1;
}

.team_area {
  padding: 120px 0px;
}

.team_area .team_item img {
  max-width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.team_area .team_item .content {
  text-align: center;
  padding-top: 25px;
}

.team_area .team_item .content h5 {
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #040c2c;
}

.team_area .team_item .content p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 10px;
}

.team_area .team_item .content .social-links {
  border-top: 1px solid #dee2e6;
  padding-top: 5px;
}

.team_area .team_item .content .social-links li {
  display: inline-block;
}

.team_area .team_item .content .social-links li a {
  font-size: 14px;
  color: #939393;
  padding: 0px 3px;
}

.team_area .team_item .content .social-links li a:hover {
  color: #ff214f;
}

.team_area .team_item:hover img {
  -webkit-filter: none;
  filter: none;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== clients_logo_area css ============*/
.clients_logo_area {
  padding: 120px 0px;
}

.clients_slider {
  text-align: center;
}

.clients_slider .item a {
  display: block;
  text-align: center;
}

.clients_slider .item img {
  max-height: 100px;
  width: auto;
  opacity: 0.7;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
}

.clients_slider .item:hover img {
  opacity: 1;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=============== blog_area css =============*/
.blog_area {
  background: #fafafa;
  padding: 120px 0px;
}

.bg_w {
  background: #fff;
}

.blog_post {
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.blog_post .blog_img {
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.blog_post .blog_img .post_date {
  position: absolute;
  width: 55px;
  height: 60px;
  font: 700 18px "Montserrat", sans-serif;
  color: #494949;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.95);
  text-align: center;
  top: 20px;
  right: 20px;
  padding-top: 8px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.blog_post .post_content {
  padding: 25px;
  background: #fff;
  border: 1px solid #ebebeb;
  border-top: 0px;
}

.blog_post .post_content .blog-meta span {
  font: 500 15px/15px "Montserrat", sans-serif;
  color: #494949;
  display: inline-block;
  padding-right: 5px;
}

.blog_post .post_content .blog-meta span i {
  padding-right: 5px;
}

.blog_post .post_content .blog-meta span a {
  color: #494949;
}

.blog_post .post_content .blog-meta span + span {
  border-left: 1px solid #494949;
  padding-left: 12px;
}

.blog_post .post_content h2 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  color: #494949;
  padding: 10px 0px 8px;
}
.blog_post .post_content h2:hover{
  color: #040c2c;
}
.blog_post .post_content .read_btn {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #797979;
  -webkit-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
}
.blog_post .post_content .read_btn i{
  vertical-align: middle;
  padding-left: 3px;
  transition: all 0.2s linear;
  display: inline-block;
}
.blog_post .post_content .read_btn:hover {
  color: #040c2c;
}
.blog_post .post_content .read_btn:hover i{
  transform: translateX(5px);
}
.blog_post:hover {
  -webkit-box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
}

.blog_post:hover .post_date {
  background: #040c2c;
  color: #fff;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============ contact-area css ===============*/
.contact-area {
  padding: 120px 0px;
}

.contact-area h4 {
  font: 600 35px/40px "Montserrat", sans-serif;
  color: #040c2c;
  text-transform: capitalize;
  padding-bottom: 24px;
}

.contact-area .input_form form .form-control {
  font-size: 14px;
  line-break: 24px;
  font-weight: 400;
  color: #494949;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ededed;
  border-radius: 0px;
  padding-left: 20px;
  text-transform: capitalize;
  min-height: 44px;
  margin-bottom: 30px;
}

.contact-area .input_form form .form-control.placeholder {
  color: #797979;
}

.contact-area .input_form form .form-control:-moz-placeholder {
  color: #797979;
}

.contact-area .input_form form .form-control::-moz-placeholder {
  color: #797979;
}

.contact-area .input_form form .form-control::-webkit-input-placeholder {
  color: #797979;
}

.contact-area .input_form form .form-control.error {
  margin-bottom: 0px;
}

.contact-area .input_form form .form-control.error + .error {
  font: 400 10px/30px "Montserrat", sans-serif;
  color: #494949;
  background: none;
  border: 0px;
  margin-bottom: 0px;
  display: block;
}

.contact-area .input_form form textarea.form-control {
  resize: none;
  height: 142px;
  padding-top: 12px;
}

.contact-area .input_form form .send_btn {
  font-size: 16px;
  border-radius: 4px;
  color: #040c2c;
  padding: 14px 38px 14px 42px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  background: transparent;
}
.contact-area .input_form form .send_btn:hover{
  color: #fff;
}
.contact-area .input_form #success, .contact-area .input_form #error {
  position: absolute;
  top: -15px;
  display: block;
  right: 0;
  left: 0;
  bottom: -15px;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

.contact-area .input_form #success p, .contact-area .input_form #error p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}

.contact-area .input_form #success {
  color: lawngreen;
}

.contact-area .input_form #error {
  color: orangered;
}

.contact-area .contact_info {
  padding-left: 50px;
}

.contact-area .contact_info ul {
  display: block;
}

.contact-area .contact_info ul .item {
  cursor: pointer;
  display: block;
}

.contact-area .contact_info ul .item .media i {
  color: #494949;
  line-height: 28px;
  text-align: center;
  font-size: 18px;
  padding-right: 10px;
}

.contact-area .contact_info ul .item .media .media-body {
  vertical-align: middle;
}

.contact-area .contact_info ul .item .media .media-body a {
  color: #797979;
  font: 400 16px/28px "Montserrat", sans-serif;
}

.contact-area .contact_info ul .item + .item {
  padding-top: 15px;
}

/*============contact map=============*/
.contact_map {
  position: relative;
  height: 500px;
}


/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*========= Start footer area css ========*/
.footer-area {
  background: #040c2c;
  position: relative;
  padding: 60px 0px 60px;
}

.footer-area .footer-content {
  text-align: center;
}

.footer-area .footer-content .logo {
  display: inline-block;
  margin-bottom: 30px;
}

.footer-area .footer-content .logo img {
  max-width: 100%;
}

.footer-area .footer-content ul li {
  display: inline-block;
  padding: 0px 5px;
}

.footer-area .footer-content ul li a {
  width: 35px;
  height: 35px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 12px;
  line-height: 35px;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.footer-area .footer-content ul li a:hover {
  background: #fff;
  color: #494949;
}

.footer-area .footer-content h6 {
  font: 400 14px/20px "Lora", serif;
  color: #999;
  padding-top: 15px;
}

.footer-area .footer-content h6 a {
  color: #fff;
}

.footer-area .footer-content p {
  font: 400 10px/18px "Montserrat", sans-serif;
  color: #fff;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.bg_color{
  background: #fafafa;
}
.work_area_two{
  background: #fff;
  padding: 110px 0px 90px;
}
.bg_w{
  background: #fff;
}


/*========= End footer area css ========*/
/*---------------------------------------------------- */

/*# sourceMappingURL=style.css.map */


/* --------------------------------------------------- *
 * Isotope (more info: http://isotope.metafizzy.co/)
/* --------------------------------------------------- */

.isotope-wrap {
	position: relative;
}
.isotope {
}


/* isotope top content 
======================= */
.isotope-top-content {
	position: relative;
	padding: 50px 3% 50px 3%;
}

/* if gutter enabled */
.isotope.gutter-1 .isotope-top-content {
	margin-left: 6px;
}
.isotope.gutter-2 .isotope-top-content {
	margin-left: 1.5%;
}
.isotope.gutter-3 .isotope-top-content {
	margin-left: 2.5%;
}

/* if boxed layout enabled */
body.tt-boxed .isotope-wrap.tt-wrap .isotope-top-content {
	padding-left: 0;
	padding-right: 0;
}


/* ================
	isotope filter 
=================== */

.isotope-filter {
}
@media (max-width: 768px) {
	.isotope-filter {
		text-align: center;
	}
}

/* isotope filter links */
ul.isotope-filter-links {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.isotope-filter-links > li {
	display: inline-block;
	margin: 0 8px;
}
@media (max-width: 768px) {
	ul.isotope-filter-links > li {
		margin-top: 5px;
		margin-bottom: 5px;
	}
}
ul.isotope-filter-links > li:first-child {
	margin-left: 0;
}
ul.isotope-filter-links > li:last-child {
	margin-right: 0;
}
ul.isotope-filter-links > li > a {
	position: relative;
	display: block;
	padding: 5px 0;
	font-size: 16px;
	color: #333;
}
ul.isotope-filter-links > li > a:after {
	position: absolute;
	content: "";
	bottom: 0;
	left: 0;
	width: 0;
	height: 3px;
	background-color: #08c1b8;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
ul.isotope-filter-links > li > a:hover:after,
ul.isotope-filter-links > li > a.active:after {
	width: 100%;
}


/* isotope filter aligns */
.isotope-filter.fi-center {
	text-align: center;
}
.isotope-filter.fi-right {
	text-align: right;
}
@media (max-width: 768px) {
	.isotope-filter.fi-right {
		text-align: center;
	}
}


/* change isotope filter to button (use class "fi-to-button") 
============================================================== */
.isotope-filter.fi-to-button {
	position: absolute;
	top: 50px;
	left: 3%;
	right: auto;
	z-index: 9998;
	padding: 0 !important;
	margin: 0 !important;
}

.isotope.gutter-1 .isotope-filter.fi-to-button {
	top: 60px;
}
.isotope.gutter-2 .isotope-filter.fi-to-button {
	top: 80px;
}
.isotope.gutter-3 .isotope-filter.fi-to-button {
	top: 100px;
}

/* isotope filter button */
.isotope-filter-button {
	position: relative;
	display: none;
	width: 55px;
	height: 55px;
	line-height: 57px;
	background-color: #08c1b8;
	text-align: center;
	font-size: 19px;
	color: #FFF;
	cursor: pointer;
	z-index: 2;
	border-radius: 60px;
	-webkit-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.25);
	box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.25);

	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.isotope-filter.fi-to-button .isotope-filter-button {
	display: block;
}
.isotope-filter.fi-to-button:hover .isotope-filter-button,
.isotope-filter.fi-to-button:focus .isotope-filter-button {
	color: #FFF;
	-webkit-box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.46);
	-moz-box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.46);
	box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.46);
}

/* isotope filter button icons */
.isotope-filter-button .ifb-icon-close {
	display: none;
}
@media (max-width: 992px) {
	.isotope-filter-button .ifb-icon-close,
	.isotope-filter.fi-to-button.iso-filter-open .isotope-filter-button .ifb-icon {
		display: none;
	}
	.isotope-filter.fi-to-button.iso-filter-open .isotope-filter-button .ifb-icon-close {
		display: block;
	}
}

/* isotope filter links */
.isotope-filter.fi-to-button ul.isotope-filter-links {
	position: absolute;
	top: 100%;
	left: 0;
	min-width: 160px;
	list-style: none;
	background-color: #111;
	padding: 25px 0 15px 0;
	margin: -25px 0 0 0;
	text-align: left;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
	-webkit-box-shadow: 0 7px 5px -6px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 7px 5px -6px rgba(0, 0, 0, 0.15);
	box-shadow: 0 7px 5px -6px rgba(0, 0, 0, 0.15);

	-moz-transform: translateY(5px);
	-ms-transform: translateY(5px);
	-webkit-transform: translateY(5px);
	transform: translateY(5px);

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
@media (max-width: 768px) {
	.isotope-filter.fi-to-button ul.isotope-filter-links {
		top: auto !important;
		bottom: 100% !important;
		left: auto !important;
		right: 0 !important;
		padding: 15px 0 30px 0 !important;
		margin: 0 0 -30px 0 !important;
	}
}
.isotope-filter.fi-to-button.iso-filter-open ul.isotope-filter-links {
	visibility: visible;
	opacity: 1;
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);

	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.isotope-filter.fi-to-button ul.isotope-filter-links > li {
	display: block;
	margin: 0;
	padding: 0 20px;
	line-height: 1.2;
}
.isotope-filter.fi-to-button ul.isotope-filter-links > li > a {
	display: block;
	padding: 8px 0;
	font-size: 15px;
	color: #DDD;
}
.isotope-filter.fi-to-button ul.isotope-filter-links > li > a:after,
.isotope-filter.fi-to-button ul.isotope-filter-links > li > a.active:after {
	content: none;
}
.isotope-filter.fi-to-button ul.isotope-filter-links > li > a:hover {
	color: #08c1b8;
}
.isotope-filter.fi-to-button ul.isotope-filter-links > li > a.active {
	color: #08c1b8;
}

/* isotope filter aligns */
.isotope-filter.fi-to-button.fi-right {
	left: auto;
	right: 3%;
}
.isotope-filter.fi-to-button.fi-right ul.isotope-filter-links {
	left: auto;
	right: 0;
	padding: 15px 0;
	margin: -27px 0 0 0;
}

/* if ".isotope-top-content" contains class "fi-to-button-on" */
.isotope-top-content.fi-to-button-on {
	margin-top: 0 !important;
	padding: 0;
}

.isotope.gutter-1 .isotope-top-content.fi-to-button-on {
	padding-top: 6px;
	padding-bottom: 0;
}
.isotope.gutter-2 .isotope-top-content.fi-to-button-on {
	padding-top: 1.5%;
	padding-bottom: 0;
}
.isotope.gutter-3 .isotope-top-content.fi-to-button-on {
	padding-top: 2.5%;
	padding-bottom: 0;
}

/* if page header enabled */
body.page-header-on .isotope-filter.fi-to-button,
body.page-header-on .isotope.gutter-1 .isotope-filter.fi-to-button,
body.page-header-on .isotope.gutter-2 .isotope-filter.fi-to-button,
body.page-header-on .isotope.gutter-3 .isotope-filter.fi-to-button {
	top: -28px !important;
}

/* if page boxed layout enabled */
body.tt-boxed .isotope-wrap.tt-wrap .isotope-filter.fi-to-button {
	left: 0;
	margin: 0;
}
body.tt-boxed .isotope-wrap.tt-wrap .isotope-filter.fi-to-button.fi-right {
	left: auto;
	right: 0;
}

@media (min-width: 768px) {
	body.tt-boxed .isotope-wrap.tt-wrap .isotope-top-content.fi-to-button-on,
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-1 .isotope-top-content.fi-to-button-on,
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-2 .isotope-top-content.fi-to-button-on,
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-3 .isotope-top-content.fi-to-button-on {
		padding-top: 50px;
	}
}

body.tt-boxed .isotope-wrap.tt-wrap .isotope-filter.fi-to-button,
body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-1 .isotope-filter.fi-to-button,
body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-2 .isotope-filter.fi-to-button,
body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-3 .isotope-filter.fi-to-button {
	top: 20px;
}

body.tt-boxed.page-header-on .isotope-wrap.tt-wrap .isotope-top-content.fi-to-button-on {
	padding-top: 40px;
	padding-bottom: 0;
}
body.tt-boxed.page-header-on .isotope-wrap.tt-wrap .isotope.gutter-1 .isotope-top-content.fi-to-button-on {
	padding-top: 40px;
	padding-bottom: 0;
}
body.tt-boxed.page-header-on .isotope-wrap.tt-wrap .isotope.gutter-2 .isotope-top-content.fi-to-button-on {
	padding-top: 40px;
	padding-bottom: 0;
}
body.tt-boxed.page-header-on .isotope-wrap.tt-wrap .isotope.gutter-3 .isotope-top-content.fi-to-button-on {
	padding-top: 40px;
	padding-bottom: 0;
}


/* move "fi-to-button" to bottom on small screens 
================================================== */
@media (max-width: 768px) {
	.isotope-filter.fi-to-button,

	body.page-header-on .isotope-filter.fi-to-button, 
	body.page-header-on .isotope.gutter-1 .isotope-filter.fi-to-button, 
	body.page-header-on .isotope.gutter-2 .isotope-filter.fi-to-button, 
	body.page-header-on .isotope.gutter-3 .isotope-filter.fi-to-button,

	body.tt-boxed .isotope-wrap.tt-wrap .isotope-filter.fi-to-button, 
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-1 .isotope-filter.fi-to-button, 
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-2 .isotope-filter.fi-to-button, 
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-3 .isotope-filter.fi-to-button {
		position: fixed;
		top: auto !important;
		bottom: 15px !important;
		left: auto !important;
		right: 15px !important;
	}
}


/* ====================
	isotope items wrap  
======================= */
.isotope-items-wrap {
	-webkit-transition: all 0.6s ease-in-out;
	-moz-transition: all 0.6s ease-in-out;
	-o-transition: all 0.6s ease-in-out;
	-ms-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
}

/* isotope items wrap (if gutter enabled) */
.isotope.gutter-1 .isotope-items-wrap {
	margin-top: 6px;
	margin-left: 6px;
	margin-right: 6px;
}
.isotope.gutter-2 .isotope-items-wrap {
	margin-top: 1.5%;
	margin-left: 1.5%;
	margin-right: 1.5%;
}
.isotope.gutter-3 .isotope-items-wrap {
	margin-top: 2.5%;
	margin-left: 2.5%;
	margin-right: 2.5%;
}

/* if boxed layout enabled */
body.tt-boxed .isotope-wrap.tt-wrap .isotope-items-wrap,
body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-3 .isotope-items-wrap {
	margin-top: 40px;
	margin-left: 0;
	margin-right: 0;
}
@media (max-width: 991px) {
	body.tt-boxed .isotope-wrap.tt-wrap .isotope-items-wrap,
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-3 .isotope-items-wrap {
		margin-top: 20px;
	}
}

/* if "isotope-top-content" exist */
body.iso-top-content-on .isotope .isotope-items-wrap {
	margin-top: 0 !important;
}


/* isotope item 
================ */
.isotope-item {
	float: left;
	width: 25%;
}

/* isotope last item (it makes last item to 100% wide). Class: "iso-last-item" */
@media (max-width : 991px) {
	.isotope-item.iso-last-item {
		width: 100% !important;
	}
}


/* isotope item heights 
======================== */
/* isotope item height-1 (use class "iso-height-1") */
.isotope-item.iso-height-1 > div,
.isotope-item.iso-height-1 > a,
.isotope-item.iso-height-1 > article {
	display: block;
	padding-bottom: 70%;
}

/* isotope item height-2 (use class "iso-height-2") */
.isotope-item.iso-height-2 > div,
.isotope-item.iso-height-2 > a,
.isotope-item.iso-height-2 > article {
	display: block;
	padding-bottom: 140%;
}

/* isotope item height-2 if "gutter-1" enabled */
.isotope.col-2.gutter-1 .isotope-item.iso-height-2 > div,
.isotope.col-2.gutter-1 .isotope-item.iso-height-2 > a,
.isotope.col-2.gutter-1 .isotope-item.iso-height-2 > article {
	padding-bottom: 140.6%;
}
.isotope.col-3.gutter-1 .isotope-item.iso-height-2 > div,
.isotope.col-3.gutter-1 .isotope-item.iso-height-2 > a,
.isotope.col-3.gutter-1 .isotope-item.iso-height-2 > article {
	padding-bottom: 140.9%;
}
.isotope.col-4.gutter-1 .isotope-item.iso-height-2 > div,
.isotope.col-4.gutter-1 .isotope-item.iso-height-2 > a,
.isotope.col-4.gutter-1 .isotope-item.iso-height-2 > article {
	padding-bottom: 141.4%;
}
.isotope.col-5.gutter-1 .isotope-item.iso-height-2 > div,
.isotope.col-5.gutter-1 .isotope-item.iso-height-2 > a,
.isotope.col-5.gutter-1 .isotope-item.iso-height-2 > article {
	padding-bottom: 141.4%;
}
.isotope.col-6.gutter-1 .isotope-item.iso-height-2 > div,
.isotope.col-6.gutter-1 .isotope-item.iso-height-2 > a,
.isotope.col-6.gutter-1 .isotope-item.iso-height-2 > article {
	padding-bottom: 141.8%;
}

/* isotope item height-2 if "gutter-2" enabled */
.isotope.col-2.gutter-2 .isotope-item.iso-height-2 > div,
.isotope.col-2.gutter-2 .isotope-item.iso-height-2 > a,
.isotope.col-2.gutter-2 .isotope-item.iso-height-2 > article {
	padding-bottom: 143.1%;
}
.isotope.col-3.gutter-2 .isotope-item.iso-height-2 > div,
.isotope.col-3.gutter-2 .isotope-item.iso-height-2 > a,
.isotope.col-3.gutter-2 .isotope-item.iso-height-2 > article {
	padding-bottom: 144.7%;
}
.isotope.col-4.gutter-2 .isotope-item.iso-height-2 > div,
.isotope.col-4.gutter-2 .isotope-item.iso-height-2 > a,
.isotope.col-4.gutter-2 .isotope-item.iso-height-2 > article {
	padding-bottom: 146.2%;
}
.isotope.col-5.gutter-2 .isotope-item.iso-height-2 > div,
.isotope.col-5.gutter-2 .isotope-item.iso-height-2 > a,
.isotope.col-5.gutter-2 .isotope-item.iso-height-2 > article {
	padding-bottom: 148%;
}
.isotope.col-6.gutter-2 .isotope-item.iso-height-2 > div,
.isotope.col-6.gutter-2 .isotope-item.iso-height-2 > a,
.isotope.col-6.gutter-2 .isotope-item.iso-height-2 > article {
	padding-bottom: 150%;
}

/* isotope item height-2 if "gutter-3" enabled */
.isotope.col-2.gutter-3 .isotope-item.iso-height-2 > div,
.isotope.col-2.gutter-3 .isotope-item.iso-height-2 > a,
.isotope.col-2.gutter-3 .isotope-item.iso-height-2 > article {
	padding-bottom: 145.2%;
}
.isotope.col-3.gutter-3 .isotope-item.iso-height-2 > div,
.isotope.col-3.gutter-3 .isotope-item.iso-height-2 > a,
.isotope.col-3.gutter-3 .isotope-item.iso-height-2 > article {
	padding-bottom: 148%;
}
.isotope.col-4.gutter-3 .isotope-item.iso-height-2 > div,
.isotope.col-4.gutter-3 .isotope-item.iso-height-2 > a,
.isotope.col-4.gutter-3 .isotope-item.iso-height-2 > article {
	padding-bottom: 150.9%;
}
.isotope.col-5.gutter-3 .isotope-item.iso-height-2 > div,
.isotope.col-5.gutter-3 .isotope-item.iso-height-2 > a,
.isotope.col-5.gutter-3 .isotope-item.iso-height-2 > article {
	padding-bottom: 154%;
}
.isotope.col-6.gutter-3 .isotope-item.iso-height-2 > div,
.isotope.col-6.gutter-3 .isotope-item.iso-height-2 > a,
.isotope.col-6.gutter-3 .isotope-item.iso-height-2 > article {
	padding-bottom: 158%;
}
@media (max-width : 1600px) {
	/* isotope item height-2 if "gutter-2" enabled */
	.isotope.col-5.gutter-2 .isotope-item.iso-height-2 > div,
	.isotope.col-5.gutter-2 .isotope-item.iso-height-2 > a,
	.isotope.col-5.gutter-2 .isotope-item.iso-height-2 > article {
		padding-bottom: 146.2%;
	}
	.isotope.col-6.gutter-2 .isotope-item.iso-height-2 > div,
	.isotope.col-6.gutter-2 .isotope-item.iso-height-2 > a,
	.isotope.col-6.gutter-2 .isotope-item.iso-height-2 > article {
		padding-bottom: 146.4%;
	}

	/* isotope item height-2 if "gutter-3" enabled */
	.isotope.col-5.gutter-3 .isotope-item.iso-height-2 > div,
	.isotope.col-5.gutter-3 .isotope-item.iso-height-2 > a,
	.isotope.col-5.gutter-3 .isotope-item.iso-height-2 > article {
		padding-bottom: 151%;
	}
	.isotope.col-6.gutter-3 .isotope-item.iso-height-2 > div,
	.isotope.col-6.gutter-3 .isotope-item.iso-height-2 > a,
	.isotope.col-6.gutter-3 .isotope-item.iso-height-2 > article {
		padding-bottom: 151%;
	}
}
@media (max-width: 992px) {
	/* isotope item height-2 if "gutter-2" enabled */
	.isotope.col-5.gutter-2 .isotope-item.iso-height-2 > div,
	.isotope.col-5.gutter-2 .isotope-item.iso-height-2 > a,
	.isotope.col-5.gutter-2 .isotope-item.iso-height-2 > article {
		padding-bottom: 143%;
	}
	.isotope.col-6.gutter-2 .isotope-item.iso-height-2 > div,
	.isotope.col-6.gutter-2 .isotope-item.iso-height-2 > a,
	.isotope.col-6.gutter-2 .isotope-item.iso-height-2 > article {
		padding-bottom: 143%;
	}

	/* isotope item height-2 if "gutter-3" enabled */
	.isotope.col-3.gutter-3 .isotope-item.iso-height-2 > div,
	.isotope.col-3.gutter-3 .isotope-item.iso-height-2 > a,
	.isotope.col-3.gutter-3 .isotope-item.iso-height-2 > article {
		padding-bottom: 145.1%;
	}
	.isotope.col-4.gutter-3 .isotope-item.iso-height-2 > div,
	.isotope.col-4.gutter-3 .isotope-item.iso-height-2 > a,
	.isotope.col-4.gutter-3 .isotope-item.iso-height-2 > article {
		padding-bottom: 145.1%;
	}
	.isotope.col-5.gutter-3 .isotope-item.iso-height-2 > div,
	.isotope.col-5.gutter-3 .isotope-item.iso-height-2 > a,
	.isotope.col-5.gutter-3 .isotope-item.iso-height-2 > article {
		padding-bottom: 145.1%;
	}
	.isotope.col-6.gutter-3 .isotope-item.iso-height-2 > div,
	.isotope.col-6.gutter-3 .isotope-item.iso-height-2 > a,
	.isotope.col-6.gutter-3 .isotope-item.iso-height-2 > article {
		padding-bottom: 145.1%;
	}
}

/* isotope col-1 if "iso-height-1" enabled */
.isotope.col-1 .isotope-item.iso-height-1 > div,
.isotope.col-1 .isotope-item.iso-height-1 > a,
.isotope.col-1 .isotope-item.iso-height-1 > article {
	display: block;
	padding-bottom: 30%;
}
@media (max-width: 768px) {
	.isotope.col-1 .isotope-item.iso-height-1 > div,
	.isotope.col-1 .isotope-item.iso-height-1 > a,
	.isotope.col-1 .isotope-item.iso-height-1 > article {
		padding-bottom: 48%;
	}
}
/* isotope col-1 if "iso-height-2" enabled */
.isotope.col-1 .isotope-item.iso-height-2 > div,
.isotope.col-1 .isotope-item.iso-height-2 > a,
.isotope.col-1 .isotope-item.iso-height-2 > article {
	display: block;
	padding-bottom: 48%;
}


/* isotope gutter (add more space between isotope items) 
=========================================================*/
/* gutter-1 */
.isotope.gutter-1 .isotope-item { padding: 0 0 6px 6px; }
.isotope.gutter-1 { margin-left: -6px; }

/* gutter-2 */
.isotope.gutter-2 .isotope-item { padding: 0 0 1.5% 1.5%; }
.isotope.gutter-2 { margin-left: -1.5%; }

/* gutter-3 */
.isotope.gutter-3 .isotope-item { padding: 0 0 2.5% 2.5%; }
.isotope.gutter-3 { margin-left: -2.5%; }


/* isotope columns 
=================== */
.isotope.col-1 .isotope-item {
	width: 100%;
}
.isotope.col-2 .isotope-item {
	width: 50%;
}
.isotope.col-3 .isotope-item {
	width: 33.33333333%;
}
.isotope.col-4 .isotope-item {
	width: 25%;
}
.isotope.col-5 .isotope-item {
	width: 20%;
}
.isotope.col-6 .isotope-item {
	width: 16.66666666666667%;
}

/* isotope grid sizer */
.grid-sizer {
	width: 25%;
}
.isotope.col-1 .grid-sizer {
	width: 100%;
}
.isotope.col-2 .grid-sizer {
	width: 50%;
}
.isotope.col-3 .grid-sizer {
	width: 33.33333333%;
}
.isotope.col-4 .grid-sizer {
	width: 25%;
}
.isotope.col-5 .grid-sizer {
	width: 20%;
}
.isotope.col-6 .grid-sizer {
	width: 16.66666666666667%;
}

/* isotope item width (alternative width) */
.isotope.col-2 .width-2 { width: 100%; }
.isotope.col-3 .width-2 { width: 66.66666666%; }
.isotope.col-4 .width-2 { width: 50%; }
.isotope.col-5 .width-2 { width: 40%; }
.isotope.col-6 .width-2 { width: 33.33333333%; }


/* isotope columns on smaller screens 
====================================== */
@media (max-width : 1600px) {
	.isotope.col-5 .isotope-item,
	.isotope.col-6 .isotope-item,

	.isotope.col-5 .grid-sizer,
	.isotope.col-6 .grid-sizer {
		width: 25%;
	}
	.isotope.col-5 .width-2,
	.isotope.col-6 .width-2 {
		width: 50%;
	}
}

@media (max-width : 1200px) {
	.isotope.col-4 .isotope-item,
	.isotope.col-5 .isotope-item,
	.isotope.col-6 .isotope-item,

	.isotope.col-4 .grid-sizer,
	.isotope.col-5 .grid-sizer,
	.isotope.col-6 .grid-sizer {
		width: 33.33333333%;
	}
	.isotope.col-4 .width-2,
	.isotope.col-5 .width-2,
	.isotope.col-6 .width-2 {
		width: 66.66666666%;
	}
}

@media (max-width : 991px) {
	.isotope.col-3 .isotope-item,
	.isotope.col-4 .isotope-item,
	.isotope.col-5 .isotope-item,
	.isotope.col-6 .isotope-item,

	.isotope.col-3 .grid-sizer,
	.isotope.col-4 .grid-sizer,
	.isotope.col-5 .grid-sizer,
	.isotope.col-6 .grid-sizer,

	.isotope.col-3 .width-2,
	.isotope.col-4 .width-2,
	.isotope.col-5 .width-2,
	.isotope.col-6 .width-2 {
		width: 50%;
	}
}

@media (max-width : 480px) {
	.isotope.col-2 .isotope-item,
	.isotope.col-3 .isotope-item,
	.isotope.col-4 .isotope-item,
	.isotope.col-5 .isotope-item,
	.isotope.col-6 .isotope-item {
		width: 100%;
	}
}


/* isotope pagination
====================== */
.isotope-pagination {
	padding-top: 60px;
	padding-bottom: 60px;
	text-align: center;
}

.isotope.gutter-1 .isotope-pagination {
	margin-left: 6px;
}
.isotope.gutter-2 .isotope-pagination {
	margin-left: 1.5%;
}
.isotope.gutter-3 .isotope-pagination {
	margin-left: 2.5%;
}


/* if isotope item contains content carousel
============================================= */
.isotope-item.iso-height-1 .iso-carousel-wrap,
.isotope-item.iso-height-2 .iso-carousel-wrap {
	position: relative;
}
.isotope-item.iso-height-1 .owl-carousel,
.isotope-item.iso-height-2 .owl-carousel {
	position: absolute;
	height: 100%;
}
.isotope-item .owl-stage-outer,
.isotope-item .owl-stage,
.isotope-item .owl-item,
.isotope-item .cc-item {
	height: 100%;
}


/* ------------------------------------------------------------- *
 * Gallery list section
/* ------------------------------------------------------------- */

#gallery-list-section {
	width: 100%;
}

/* Gallery list item 
===================== */
.gallery-list-item { 
	position: relative;
}
.gallery-list-item { 
	margin-bottom: 30px;
}
.iso-height-1 .gallery-list-item,
.iso-height-2 .gallery-list-item { 
	margin-bottom: 90px;
}

/* gallery list item if isotope gutters enabled */
.isotope.gutter-2 .gallery-list-item { 
	margin-bottom: 10px;
}
.isotope.gutter-3 .gallery-list-item { 
	margin-bottom: 0;
}
.isotope.gutter-2 .iso-height-1 .gallery-list-item,
.isotope.gutter-2 .iso-height-2 .gallery-list-item { 
	margin-bottom: 70px;
}
.isotope.gutter-3 .iso-height-1 .gallery-list-item,
.isotope.gutter-3 .iso-height-2 .gallery-list-item { 
	margin-bottom: 55px;
}

/* gallery list item image */
.gallery-list-item .gl-item-image-wrap {
	position: relative;
}

.gallery-list-item .gl-item-image-inner {
	display: block;
	position: relative;
	overflow: hidden;
}
.gallery-list-item .gl-item-image-inner:before {
	position: absolute;
	content: "";
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: transparent;
	z-index: 2;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.gallery-list-item .gl-item-image-inner:hover:before {
	background-color: #FFF;
	opacity: .85;
}

.gallery-list-item .gl-item-image {
}
.gallery-list-item .gl-item-image-inner img {
}

.gallery-list-item .gl-item-image-zoom,
.gallery-list-item .gl-item-image-lock {
	position: absolute;
	right: 20px;
	bottom: -20px;
	width: 25px;
	height: 25px;
	opacity: 0;
	z-index: 4;
	font-size: 24px;
	color: #222;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
@media (max-width: 992px) {
	.gallery-list-item .gl-item-image-zoom,
	.gallery-list-item .gl-item-image-lock {
		display: none;
	}
}
.gallery-list-item .gl-item-image-zoom:hover {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
.gallery-list-item .gl-item-image-zoom:before,
.gallery-list-item .gl-item-image-zoom:after {
	content: '';
	position: absolute;
	background-color: #111;
}
.gallery-list-item .gl-item-image-zoom:before {
	left: 0;
	top: 11px;
	width: 100%;
	height: 3px;
}
.gallery-list-item .gl-item-image-zoom:after {
	left: 11px;
	top: 0;
	width: 3px;
	height: 100%;
}
.gallery-list-item .gl-item-image-inner:hover .gl-item-image-zoom,
.gallery-list-item .gl-item-image-inner:hover .gl-item-image-lock {
	bottom: 20px;
	opacity: 1;
	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.gallery-list-item .gl-item-image-inner:hover .gl-item-image-zoom:hover {
	-webkit-transition-delay: 0s;
	-moz-transition-delay: 0s;
	transition-delay: 0s;
}

.iso-height-1 .gallery-list-item .gl-item-image-wrap,
.iso-height-2 .gallery-list-item .gl-item-image-wrap,
.iso-height-1 .gallery-list-item .gl-item-image-inner,
.iso-height-2 .gallery-list-item .gl-item-image-inner,
.iso-height-1 .gallery-list-item .gl-item-image,
.iso-height-2 .gallery-list-item .gl-item-image {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.gallery-list-item .gl-item-image,
.iso-height-1 .gallery-list-item .gl-item-image,
.iso-height-2 .gallery-list-item .gl-item-image {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.gallery-list-item .gl-item-image-inner:hover .gl-item-image,
.iso-height-1 .gallery-list-item .gl-item-image-inner:hover .gl-item-image,
.iso-height-2 .gallery-list-item .gl-item-image-inner:hover .gl-item-image {
	-webkit-transform: scale(1.07);
	-moz-transform: scale(1.07);
	-o-transform: scale(1.07);
	-ms-transform: scale(1.07);
	transform: scale(1.07);
}

/* gallery list item info */
.gl-item-info {
	position: relative;
	width: 100%;
	height: 55px;
	line-height: 1.4;
	padding: 15px 75px 0 20px;

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
@media (max-width: 480px) {
	.gl-item-info { 
		padding-left: 0;
	}
}

.iso-height-1 .gl-item-info,
.iso-height-2 .gl-item-info {
	position: absolute;
	left: 0;
	top: 100%;
}

/* gallery list item info if isotope gutters enabled */
@media (min-width: 768px) {
	.isotope.gutter-2 .gl-item-info,
	.isotope.gutter-3 .gl-item-info { 
		padding-left: 0;
	}
}

/* gallery list item title */
.gl-item-title {
	margin: 0;
	color: #333;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.2;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.gl-item-title a {
	color: #333;
}
.gl-item-title a:hover {
	color: #08c1b8;
}

/* gallery list item category */
.gl-item-category {
	display: block;
	margin-top: 6px;
	font-size: 13px;
	color: #AAA;
}
.gl-item-category a {
	color: #AAA;
}
.gl-item-category a:hover {
	opacity: .8;
}

/* gallery list item meta */
.gli-meta {
	position: absolute;
	display: inline-block;
	bottom: -36px;
	right: 20px;
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 14px;
	color: #a7a7a7;
	z-index: 9;
}
@media (max-width: 480px) {
	.gli-meta { 
		right: 0;
	}
}
.gli-meta > li {
	display: inline-block;
	margin-left: 5px;
}
.gli-meta > li a {
	font-size: 14px;
	color: #a7a7a7;
}

/* gallery list item meta if isotope gutters enabled */
.isotope.gutter-2 .gli-meta,
.isotope.gutter-3 .gli-meta { 
	right: 0;
}


/* Gallery list item alter styles 
================================== */
/* alter style 1 */
.gli-alter-1 .gallery-list-item,
.isotope.gutter-2 .gli-alter-1 .gallery-list-item,
.isotope.gutter-3 .gli-alter-1 .gallery-list-item { 
	margin-bottom: 0;
}

.gli-alter-1 .gallery-list-item .gl-item-image-inner:before,
.gli-alter-1 .gallery-list-item .gl-item-image-inner:hover:before {
	content: none;
}
.gli-alter-1 .gallery-list-item .gl-item-image,
.gli-alter-1 .iso-height-1 .gallery-list-item .gl-item-image, 
.gli-alter-1 .iso-height-2 .gallery-list-item .gl-item-image {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.gli-alter-1 .gallery-list-item:hover .gl-item-image-wrap .gl-item-image, 
.gli-alter-1 .iso-height-1 .gallery-list-item:hover .gl-item-image-wrap .gl-item-image, 
.gli-alter-1 .iso-height-2 .gallery-list-item:hover .gl-item-image-wrap .gl-item-image {
	-webkit-transform: scale(1.07);
	-moz-transform: scale(1.07);
	-o-transform: scale(1.07);
	-ms-transform: scale(1.07);
	transform: scale(1.07);
}

.gli-alter-1 .gl-item-info,
.isotope.gutter-2 .gli-alter-1 .gallery-list-item .gl-item-info,
.isotope.gutter-3 .gli-alter-1 .gallery-list-item .gl-item-info {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	height: auto;
	padding: 25px 60px 15px 5%;
	z-index: 3;
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(17,17,17,0.8) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(17,17,17,0.8) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(17,17,17,0.8) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc111111',GradientType=0 );
}
@media (max-width: 992px) {
	.gli-alter-1 .gl-item-info
	.isotope.gutter-2 .gli-alter-1 .gallery-list-item .gl-item-info,
	.isotope.gutter-3 .gli-alter-1 .gallery-list-item .gl-item-info {
		padding-right: 5%;
	}
}

.gli-alter-1 .gl-item-title {
	color: #FFF;
	font-size: 20px;
}
@media (max-width: 992px) {
	.gli-alter-1 .gl-item-title {
		font-size: 16px;
	}
}
.gli-alter-1 .gl-item-title a {
	color: #FFF;
}
.gli-alter-1 .gl-item-title a:hover {
	opacity: .9;
}

.gli-alter-1 .gl-item-category,
.gli-alter-1 .gl-item-category a {
	color: #CCC;
}

.gli-alter-1 .gallery-list-item .gli-meta,
.isotope.gutter-2 .gli-alter-1 .gallery-list-item .gli-meta,
.isotope.gutter-3 .gli-alter-1 .gallery-list-item .gli-meta {
	bottom: 14px;
	right: 15px;
	color: #FFF;
	z-index: 9;
}
.gli-alter-1 .gallery-list-item .gli-meta a {
	color: #FFF;
}

.gli-alter-1 .gallery-list-item .gl-item-image-zoom,
.gli-alter-1 .gallery-list-item .gl-item-image-lock {
	display: none;
}

/* alter style 2 */
.gli-alter-2 .gallery-list-item,
.isotope.gutter-2 .gli-alter-2 .gallery-list-item,
.isotope.gutter-3 .gli-alter-2 .gallery-list-item { 
	margin-bottom: 0;
}

.gli-alter-2 .gallery-list-item .gl-item-image-inner:before,
.gli-alter-2 .gallery-list-item .gl-item-image-inner:hover:before {
	content: none;
}
.gli-alter-2 .gallery-list-item .gl-item-image,
.gli-alter-2 .iso-height-1 .gallery-list-item .gl-item-image, 
.gli-alter-2 .iso-height-2 .gallery-list-item .gl-item-image {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.gli-alter-2 .gallery-list-item:hover .gl-item-image,
.gli-alter-2 .iso-height-1 .gallery-list-item:hover .gl-item-image, 
.gli-alter-2 .iso-height-2 .gallery-list-item:hover .gl-item-image {
	-webkit-transform: scale(1.07);
	-moz-transform: scale(1.07);
	-o-transform: scale(1.07);
	-ms-transform: scale(1.07);
	transform: scale(1.07);
}

.gli-alter-2 .gl-item-info,
.isotope.gutter-2 .gli-alter-2 .gallery-list-item .gl-item-info,
.isotope.gutter-3 .gli-alter-2 .gallery-list-item .gl-item-info {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	height: auto;
	padding: 15px 5% 15px 5%;
	z-index: 3;
	background-color: rgba(255, 255, 255, 0.9);
}
@media (max-width: 992px) {
	.gli-alter-2 .gl-item-info
	.isotope.gutter-2 .gli-alter-2 .gallery-list-item .gl-item-info,
	.isotope.gutter-3 .gli-alter-2 .gallery-list-item .gl-item-info {
		padding-right: 5%;
	}
}
.gli-alter-2 .gl-item-title {
	color: #333;
	font-size: 18px;
}
@media (max-width: 992px) {
	.gli-alter-2 .gl-item-title {
		font-size: 16px;
	}
}
.gli-alter-2 .gl-item-title a {
	color: #333;
}
.gli-alter-2 .gl-item-title a:hover {
	color: #08c1b8;
}

.gli-alter-2 .gl-item-category,
.gli-alter-2 .gl-item-category a {
	color: #666;
}

.gli-alter-2 .gallery-list-item .gli-meta,
.isotope.gutter-2 .gli-alter-2 .gallery-list-item .gli-meta,
.isotope.gutter-3 .gli-alter-2 .gallery-list-item .gli-meta {
	bottom: 14px;
	right: 15px;
	color: #888;
	z-index: 9;
}
.gli-alter-2 .gallery-list-item .gli-meta a {
	color: #888;
}

.gli-alter-2 .gallery-list-item .gl-item-image-zoom,
.gli-alter-2 .gallery-list-item .gl-item-image-lock {
	display: none;
}

/* alter style 3 */
.gli-alter-3 .gallery-list-item,
.isotope.gutter-2 .gli-alter-3 .gallery-list-item,
.isotope.gutter-3 .gli-alter-3 .gallery-list-item { 
	margin-bottom: 0;
	overflow: hidden;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}
@media (min-width: 992px) {
	.isotope.gutter-2 .gli-alter-3 .gallery-list-item:hover,
	.isotope.gutter-3 .gli-alter-3 .gallery-list-item:hover { 
		-webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .4);
		-moz-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .4);
		box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .4);

		-webkit-transform: translateY(-7px) translateZ(0);
		-moz-transform: translateY(-7px) translateZ(0);
		-o-transform: translateY(-7px) translateZ(0);
		-ms-transform: translateY(-7px) translateZ(0);
		transform: translateY(-7px) translateZ(0);
	}
}
@media (max-width: 991px) {
	.isotope.gutter-2 .gli-alter-3 .gallery-list-item,
	.isotope.gutter-3 .gli-alter-3 .gallery-list-item { 
		-webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
		box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
	}
}

.gli-alter-3 .gallery-list-item .gl-item-image-inner:before,
.gli-alter-3 .gallery-list-item .gl-item-image-inner:hover:before {
	content: none;
}
.gli-alter-3 .gallery-list-item:hover .gl-item-image-wrap .gl-item-image {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.gli-alter-3 .gl-item-info,
.isotope.gutter-2 .gli-alter-3 .gallery-list-item .gl-item-info,
.isotope.gutter-3 .gli-alter-3 .gallery-list-item .gl-item-info {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	height: auto;
	padding: 15px 65px 15px 5%;
	z-index: 3;
	background-color: #FFF;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}
@media (max-width: 992px) {
	.gli-alter-3 .gl-item-info,
	.isotope.gutter-2 .gli-alter-3 .gallery-list-item .gl-item-info,
	.isotope.gutter-3 .gli-alter-3 .gallery-list-item .gl-item-info { 
		position: absolute;
		top: auto;
		bottom: 0;
		left: 0;
		height: auto;
		padding: 10px 5% 8px 5%;
		opacity: 1;
		visibility: visible;
	}
}
.gli-alter-3 .gallery-list-item:hover .gl-item-info,
.isotope.gutter-2 .gli-alter-3 .gallery-list-item:hover .gl-item-info,
.isotope.gutter-3 .gli-alter-3 .gallery-list-item:hover .gl-item-info {
	opacity: 1;
	visibility: visible;
}

.gli-alter-3 .gl-item-title {
	color: #333;
	font-size: 18px;
}
@media (max-width: 992px) {
	.gli-alter-3 .gl-item-title {
		font-size: 16px;
	}
}
.gli-alter-3 .gl-item-title a {
	color: #333;
}
.gli-alter-3 .gl-item-title a:hover {
	color: #08c1b8;
}

.gli-alter-3 .gl-item-category,
.gli-alter-3 .gl-item-category a {
	color: #888;
}

.gli-alter-3 .gallery-list-item .gli-meta,
.isotope.gutter-2 .gli-alter-3 .gallery-list-item .gli-meta,
.isotope.gutter-3 .gli-alter-3 .gallery-list-item .gli-meta {
	bottom: 14px;
	right: 15px;
	color: #888;
	z-index: 9;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}
@media (max-width: 992px) {
	.gli-alter-3 .gallery-list-item .gli-meta,
	.isotope.gutter-2 .gli-alter-3 .gallery-list-item .gli-meta,
	.isotope.gutter-3 .gli-alter-3 .gallery-list-item .gli-meta { 
		opacity: 1;
		visibility: visible;
	}
}
.gli-alter-3 .gallery-list-item .gli-meta a {
	color: #888;
}
.gli-alter-3 .gallery-list-item:hover .gli-meta,
.isotope.gutter-2 .gli-alter-3 .gallery-list-item:hover .gli-meta,
.isotope.gutter-3 .gli-alter-3 .gallery-list-item:hover .gli-meta {
	opacity: 1;
	visibility: visible;
}

.gli-alter-3 .gallery-list-item .gl-item-image-zoom,
.gli-alter-3 .gallery-list-item .gl-item-image-lock {
	display: none;
}

/* alter style 4 */
.gli-alter-4 .gallery-list-item,
.isotope.gutter-2 .gli-alter-4 .gallery-list-item,
.isotope.gutter-3 .gli-alter-4 .gallery-list-item { 
	margin-bottom: 0;
}

.gli-alter-4 .gallery-list-item .gl-item-image, 
.gli-alter-4 .iso-height-1 .gallery-list-item .gl-item-image, 
.gli-alter-4 .iso-height-2 .gallery-list-item .gl-item-image {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.gli-alter-4 .gallery-list-item:hover .gl-item-image-wrap .gl-item-image,
.gli-alter-4 .iso-height-1 .gallery-list-item:hover .gl-item-image-wrap .gl-item-image, 
.gli-alter-4 .iso-height-2 .gallery-list-item:hover .gl-item-image-wrap .gl-item-image {
	-webkit-transform: scale(1.07);
	-moz-transform: scale(1.07);
	-o-transform: scale(1.07);
	-ms-transform: scale(1.07);
	transform: scale(1.07);
}
.gli-alter-4 .gallery-list-item:hover .gl-item-image-inner:before,
.gli-alter-4 .gallery-list-item:hover .gl-item-image-inner:hover:before {
	background-color: #FFF;
	opacity: .9;
}
@media (max-width: 992px) {
	.gli-alter-4 .gallery-list-item:hover .gl-item-image-inner:before,
	.gli-alter-4 .gallery-list-item:hover .gl-item-image-inner:hover:before {
		content: none;
	}
}

.gli-alter-4 .gl-item-info,
.isotope.gutter-2 .gli-alter-4 .gallery-list-item .gl-item-info,
.isotope.gutter-3 .gli-alter-4 .gallery-list-item .gl-item-info {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	height: auto;
	padding: 0 60px 5% 6%;
	z-index: 3;
	opacity: 0;
	background-color: transparent;
}
@media (max-width: 992px) {
	.gli-alter-4 .gl-item-info,
	.isotope.gutter-2 .gli-alter-4 .gallery-list-item .gl-item-info,
	.isotope.gutter-3 .gli-alter-4 .gallery-list-item .gl-item-info { 
		position: absolute;
		top: auto;
		bottom: 0;
		left: 0;
		height: auto;
		padding: 8px 5% 12px 5%;
		background-color: transparent;
		opacity: 1;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(17,17,17,0.8) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(17,17,17,0.8) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(17,17,17,0.8) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc111111',GradientType=0 );
	}
}
.gli-alter-4 .gallery-list-item:hover .gl-item-info,
.isotope.gutter-2 .gli-alter-4 .gallery-list-item:hover .gl-item-info,
.isotope.gutter-3 .gli-alter-4 .gallery-list-item:hover .gl-item-info { 
	opacity: 1;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;

	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.gli-alter-4 .gl-item-title {
	font-size: 22px;
	white-space: normal;
}
@media (max-width: 992px) {
	.gli-alter-4 .gl-item-title {
		font-size: 16px;
	}
	@media (max-width: 991px) {
		.gli-alter-4 .gl-item-title a {
			color: #FFF;
		}
	}
}

.gli-alter-4 .gl-item-category {
	margin-top: 8px;
}
.gli-alter-4 .gl-item-category,
.gli-alter-4 .gl-item-category a {
	color: #666;
}
@media (max-width: 992px) {
	.gli-alter-4 .gl-item-category, 
	.gli-alter-4 .gl-item-category a {
		color: #CCC;
	}
}

.gli-alter-4 .gallery-list-item .gli-meta,
.isotope.gutter-2 .gli-alter-4 .gallery-list-item .gli-meta,
.isotope.gutter-3 .gli-alter-4 .gallery-list-item .gli-meta {
	top: 20px;
	bottom: auto;
	right: 20px;
	padding: 10px;
	color: #666;
	z-index: 9;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
@media (max-width: 992px) {
	.gli-alter-4 .gallery-list-item .gli-meta,
	.isotope.gutter-2 .gli-alter-4 .gallery-list-item .gli-meta,
	.isotope.gutter-3 .gli-alter-4 .gallery-list-item .gli-meta {
		top: auto;
		bottom: 0;
		left: auto;
		right: 0;
		opacity: 1;
		visibility: visible;
		color: #FFF;
	}
}
.gli-alter-4 .gallery-list-item:hover .gli-meta,
.isotope.gutter-2 .gli-alter-4 .gallery-list-item:hover .gli-meta,
.isotope.gutter-3 .gli-alter-4 .gallery-list-item:hover .gli-meta {
	opacity: 1;
	visibility: visible;
}
.gli-alter-4 .gallery-list-item .gli-meta a {
	color: #666;
}

.gli-alter-4 .gallery-list-item .gl-item-image-zoom,
.gli-alter-4 .gallery-list-item .gl-item-image-lock {
	right: 20px;
}
.gli-alter-4 .gallery-list-item:hover .gl-item-image-zoom,
.gli-alter-4 .gallery-list-item:hover .gl-item-image-lock {
	bottom: 20px;
	opacity: 1;
	-webkit-transition-delay: 0.3s;
	-moz-transition-delay: 0.3s;
	transition-delay: 0.3s;
}
.gli-alter-4 .gallery-list-item .gl-item-image-zoom:hover {
	-webkit-transition-delay: 0s;
	-moz-transition-delay: 0s;
	transition-delay: 0s;
}

/* alter style 5 */
.gli-alter-5 .gallery-list-item,
.isotope.gutter-2 .gli-alter-5 .gallery-list-item,
.isotope.gutter-3 .gli-alter-5 .gallery-list-item { 
	margin-bottom: 0;
}

.gli-alter-5:hover .gallery-list-item .gl-item-image-inner:before {
	opacity: .5 !important;
}
.gli-alter-5 .gallery-list-item .gl-item-image-inner:before {
	background-color: #000 !important;
	opacity: .2 !important;
}
.gli-alter-5 .gallery-list-item:hover .gl-item-image-inner:before {
	opacity: .1 !important;
}

.gli-alter-5 .gallery-list-item .gl-item-image, 
.gli-alter-5 .iso-height-1 .gallery-list-item .gl-item-image, 
.gli-alter-5 .iso-height-2 .gallery-list-item .gl-item-image {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.gli-alter-5 .gallery-list-item:hover .gl-item-image,
.gli-alter-5 .iso-height-1 .gallery-list-item:hover .gl-item-image, 
.gli-alter-5 .iso-height-2 .gallery-list-item:hover .gl-item-image {
	-webkit-transform: scale(1.07);
	-moz-transform: scale(1.07);
	-o-transform: scale(1.07);
	-ms-transform: scale(1.07);
	transform: scale(1.07);
}

.gli-alter-5 .gl-item-info,
.isotope.gutter-2 .gli-alter-5 .gallery-list-item .gl-item-info,
.isotope.gutter-3 .gli-alter-5 .gallery-list-item .gl-item-info {
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	height: auto;
	padding: 0 15% 5% 5%;
	z-index: 3;
	background-color: transparent;
}

.gli-alter-5 .gl-item-title {
	color: #FFF;
	font-size: 26px;
	font-weight: bold;
	font-weight: 600;
	white-space: normal;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
@media (max-width: 992px) {
	.gli-alter-5 .gl-item-title {
		font-size: 22px;
	}
}
.gli-alter-5 .gl-item-title a {
	color: #FFF !important;
	display: block;
}
.gli-alter-5 .gl-item-title a:hover {
	color: #FFF !important;
}

.gli-alter-5:hover .gallery-list-item .gl-item-title {
	opacity: .4;
}
.gli-alter-5 .gallery-list-item:hover .gl-item-title {
	opacity: 1;
}

.gli-alter-5 .gl-item-category {
	display: none;
}

.gli-alter-5 .gallery-list-item .gli-meta {
	display: none;
}

.gli-alter-5 .gallery-list-item .gl-item-image-zoom,
.gli-alter-5 .gallery-list-item .gl-item-image-lock {
	display: none;
}

/* alter style - hover dark overlay */
.gli-dark .gallery-list-item .gl-item-image-inner:hover:before,
.gli-dark.gli-alter-4 .gallery-list-item:hover .gl-item-image-inner:before,
.gli-dark.gli-alter-4 .gallery-list-item:hover .gl-item-image-inner:hover:before,
.gli-dark.gli-alter-5 .gallery-list-item:hover .gl-item-image-inner:before {
	background-color: #000 !important;
	opacity: .75 !important;
}
.gli-dark.gli-alter-5 .gallery-list-item .gl-item-image-inner:before {
	background-color: #000 !important;
	opacity: .2 !important;
}
.gli-dark.gli-alter-5:hover .gallery-list-item .gl-item-title {
	opacity: 1 !important;
}

.gli-dark.gli-alter-4 .gl-item-title,
.gli-dark.gli-alter-4 .gl-item-title a {
	color: #FFF !important;
}

.gli-dark.gli-alter-4 .gl-item-category,
.gli-dark.gli-alter-4 .gl-item-category a {
	color: #EEE !important;
}

.gli-dark.gli-alter-4 .gallery-list-item .gli-meta,
.gli-dark.gli-alter-4 .gallery-list-item .gli-meta a,
.gli-dark.gli-alter-4 .gallery-list-item .gli-meta .favorite-btn.active .icon-heart-filled {
	color: #FFF !important;
}

.gli-dark .gallery-list-item .gl-item-image-zoom:before, 
.gli-dark .gallery-list-item .gl-item-image-zoom:after,
.gli-dark.gli-alter-4 .gallery-list-item .gl-item-image-zoom:before, 
.gli-dark.gli-alter-4 .gallery-list-item .gl-item-image-zoom:after {
	background-color: #FFF;
}
.gli-dark.gli-alter-2 .gallery-list-item .gl-item-image-zoom:before, 
.gli-dark.gli-alter-2 .gallery-list-item .gl-item-image-zoom:after,
.gli-dark.gli-alter-3 .gallery-list-item .gl-item-image-zoom:before, 
.gli-dark.gli-alter-3 .gallery-list-item .gl-item-image-zoom:after {
	background-color: #111;
}

.gli-dark.gli-alter-2 .gallery-list-item .gl-item-image-lock, 
.gli-dark.gli-alter-3 .gallery-list-item .gl-item-image-lock {
	color: #111;
}
.gli-dark .gallery-list-item .gl-item-image-lock {
	color: #FFF;
}

/* alter style - hover colored overlay */
.gli-colored .gallery-list-item .gl-item-image-inner:hover:before,
.gli-colored.gli-alter-4 .gallery-list-item:hover .gl-item-image-inner:before,
.gli-colored.gli-alter-4 .gallery-list-item:hover .gl-item-image-inner:hover:before,
.gli-colored.gli-alter-5 .gallery-list-item:hover .gl-item-image-inner:before {
	background-color: #08c1b8 !important;
	opacity: .85 !important;
}
.gli-colored.gli-alter-5 .gallery-list-item .gl-item-image-inner:before {
	background-color: #000 !important;
	opacity: .2 !important;
}
.gli-colored.gli-alter-5:hover .gallery-list-item .gl-item-title {
	opacity: 1 !important;
}

.gli-colored.gli-alter-4 .gl-item-title,
.gli-colored.gli-alter-4 .gl-item-title a {
	color: #FFF !important;
}

.gli-colored.gli-alter-4 .gl-item-category,
.gli-colored.gli-alter-4 .gl-item-category a {
	color: #EEE !important;
}

.gli-colored.gli-alter-4 .gallery-list-item .gli-meta,
.gli-colored.gli-alter-4 .gallery-list-item .gli-meta a {
	color: #FFF !important;
}

.gli-colored.gli-alter-4 .gallery-list-item .gli-meta .favorite-btn .icon-heart-empty:before {
	color: rgba(255, 255, 255, 0.5);
}
.gli-colored.gli-alter-4 .gallery-list-item .gli-meta .favorite-btn.active .icon-heart-filled:before {
	color: #FFF;
}

.gli-colored .gallery-list-item .gl-item-image-zoom:before, 
.gli-colored .gallery-list-item .gl-item-image-zoom:after,
.gli-colored.gli-alter-4 .gallery-list-item .gl-item-image-zoom:before, 
.gli-colored.gli-alter-4 .gallery-list-item .gl-item-image-zoom:after {
	background-color: #FFF;
}
.gli-colored.gli-alter-2 .gallery-list-item .gl-item-image-zoom:before, 
.gli-colored.gli-alter-2 .gallery-list-item .gl-item-image-zoom:after,
.gli-colored.gli-alter-3 .gallery-list-item .gl-item-image-zoom:before, 
.gli-colored.gli-alter-3 .gallery-list-item .gl-item-image-zoom:after {
	background-color: #111;
}

.gli-colored.gli-alter-2 .gallery-list-item .gl-item-image-lock, 
.gli-colored.gli-alter-3 .gallery-list-item .gl-item-image-lock {
	color: #111;
}
.gli-colored .gallery-list-item .gl-item-image-lock {
	color: #FFF;
}


/* alter style - image frame */
.isotope.gutter-1 .gli-frame .gl-item-image-wrap,
.isotope.gutter-2 .gli-frame .gl-item-image-wrap,
.isotope.gutter-3 .gli-frame .gl-item-image-wrap {
	background-color: #FFF;
	border: 1px solid #e5e5e5;
	border-radius: 3px;
}
.isotope.gutter-1 .gli-alter-3.gli-frame .gallery-list-item,
.isotope.gutter-2 .gli-alter-3.gli-frame .gallery-list-item,
.isotope.gutter-3 .gli-alter-3.gli-frame .gallery-list-item {
	border-radius: 3px;
	-webkit-box-shadow: 0 11px 10px -5px rgba(0, 0, 0, 0.08);
	-moz-box-shadow: 0 11px 10px -5px rgba(0, 0, 0, 0.08);
	box-shadow: 0 11px 10px -5px rgba(0, 0, 0, 0.08);
}
@media (min-width: 992px) {
	.isotope.gutter-1 .gli-alter-3.gli-frame .gallery-list-item:hover,
	.isotope.gutter-2 .gli-alter-3.gli-frame .gallery-list-item:hover,
	.isotope.gutter-3 .gli-alter-3.gli-frame .gallery-list-item:hover {
	-webkit-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .4);
	-moz-box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .4);
	box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .4);
	-webkit-transform: translateY(-7px) translateZ(0);
	-moz-transform: translateY(-7px) translateZ(0);
	-o-transform: translateY(-7px) translateZ(0);
	-ms-transform: translateY(-7px) translateZ(0);
	transform: translateY(-7px) translateZ(0);
	}
}
.isotope.gutter-1 .gli-frame .gallery-list-item .gl-item-image-inner,
.isotope.gutter-2 .gli-frame .gallery-list-item .gl-item-image-inner,
.isotope.gutter-3 .gli-frame .gallery-list-item .gl-item-image-inner {
	margin-top: 15px;
	margin-bottom: 15px;
	margin-left: 15px;
	margin-right: 15px;
}
.isotope.gutter-1 .gli-frame.gli-alter-1 .gallery-list-item .gli-meta,
.isotope.gutter-1 .gli-frame.gli-alter-2 .gallery-list-item .gli-meta,

.isotope.gutter-2 .gli-frame.gli-alter-1 .gallery-list-item .gli-meta,
.isotope.gutter-2 .gli-frame.gli-alter-2 .gallery-list-item .gli-meta,

.isotope.gutter-3 .gli-frame.gli-alter-1 .gallery-list-item .gli-meta,
.isotope.gutter-3 .gli-frame.gli-alter-2 .gallery-list-item .gli-meta {
	bottom: 25px;
	right: 30px;
}
.isotope.gutter-1 .gli-frame.gli-alter-1 .gallery-list-item .gl-item-info,
.isotope.gutter-1 .gli-frame.gli-alter-2 .gallery-list-item .gl-item-info,
.isotope.gutter-1 .gli-frame.gli-alter-4 .gallery-list-item .gl-item-info,
.isotope.gutter-1 .gli-frame.gli-alter-5 .gallery-list-item .gl-item-info,

.isotope.gutter-2 .gli-frame.gli-alter-1 .gallery-list-item .gl-item-info,
.isotope.gutter-2 .gli-frame.gli-alter-2 .gallery-list-item .gl-item-info,
.isotope.gutter-2 .gli-frame.gli-alter-4 .gallery-list-item .gl-item-info,
.isotope.gutter-2 .gli-frame.gli-alter-5 .gallery-list-item .gl-item-info,

.isotope.gutter-3 .gli-frame.gli-alter-1 .gallery-list-item .gl-item-info,
.isotope.gutter-3 .gli-frame.gli-alter-2 .gallery-list-item .gl-item-info,
.isotope.gutter-3 .gli-frame.gli-alter-4 .gallery-list-item .gl-item-info,
.isotope.gutter-3 .gli-frame.gli-alter-5 .gallery-list-item .gl-item-info {
	width: auto;
	bottom: 15px;
	left: 15px;
	right: 15px;
}
.isotope.gutter-1 .gli-frame.gli-alter-3 .gallery-list-item .gl-item-info,
.isotope.gutter-2 .gli-frame.gli-alter-3 .gallery-list-item .gl-item-info,
.isotope.gutter-3 .gli-frame.gli-alter-3 .gallery-list-item .gl-item-info {
	width: auto;
	bottom: 1px;
	left: 1px;
	right: 1px;
	border-radius: 3px 0 3px 0;
}


/* Gallery list categories 
=========================== */
.gl-categories {
}
.gl-categories .gl-item-title,
.gl-categories .gli-alter-4 .gl-item-title {
	font-size: 26px;
}
.gl-categories .gli-alter-1 .gl-item-title,
.gl-categories .gli-alter-2 .gl-item-title,
.gl-categories .gli-alter-3 .gl-item-title {
	font-size: 22px;
}
.gl-categories .gl-item-category,
.gl-categories .gli-meta {
	display: none;
}


/* Isotope items in gallery list section 
========================================= */
@media (max-width: 992px) {
	#gallery-list-section .isotope-item {
		margin-bottom: 15px;
	}
	#gallery-list-section .gli-alter-1 .isotope-item,
	#gallery-list-section .gli-alter-2 .isotope-item,
	#gallery-list-section .gli-alter-3 .isotope-item,
	#gallery-list-section .gli-alter-4 .isotope-item,
	#gallery-list-section .gli-alter-5 .isotope-item {
		margin-bottom: 0;
	}
}

@media (max-width: 768px) {
	.gl-categories .isotope.col-2 .isotope-item,
	.gl-categories .isotope.col-3 .isotope-item,
	.gl-categories .isotope.col-4 .isotope-item,
	.gl-categories .isotope.col-5 .isotope-item,
	.gl-categories .isotope.col-6 .isotope-item {
		width: 50%;
	}
}


/* ------------------------------------------------------------- *
 * Gallery list carousel
/* ------------------------------------------------------------- */

.gallery-list-carousel {
}

.gallery-list-carousel-inner {
}

.gl-carousel-wrap {
	position: relative;
	background-color: rgba(128, 128, 128, 0.1);
	padding: 40px;
}
@media (max-width: 992px) {
	.gl-carousel-wrap {
		padding: 15px;
	}
}

/* gallery list carousel no crop */
@media (min-width: 992px) {
	.glc-no-crop .owl-carousel .owl-stage-outer {
		margin-left: -1px;
		padding-left: 60px;
	}
	.glc-no-crop .gl-carousel-wrap {
		padding: 40px 0;
	}
}

/* gallery list carousel cover (for slider center) */
@media (min-width: 992px) {
	.gallery-list-carousel.glc-center .cc-item .cover {
		background-color: rgba(255, 255, 255, 0.7);
	}
	.gallery-list-carousel.glc-center .owl-item.center .cc-item .cover {
		background-color: transparent;
	}
}


/* Gallery list carousel full height offsets 
============================================= */
.gallery-list-carousel .owl-carousel.cc-height-full, 
.gallery-list-carousel .owl-carousel.cc-height-full .cc-item {
	height: calc(100vh - 161px);
}
@media (max-width: 991px) {
	.gallery-list-carousel .owl-carousel.cc-height-full, 
	.gallery-list-carousel .owl-carousel.cc-height-full .cc-item {
		height: calc(100vh - 92px);
	}
}

@media (min-width: 992px) {
	body.footer-minimal-on .gallery-list-carousel .owl-carousel.cc-height-full, 
	body.footer-minimal-on .gallery-list-carousel .owl-carousel.cc-height-full .cc-item {
		height: calc(100vh - 242px);
	}
}

/* if class "no-padding" enabled */
@media (min-width: 992px) {
	.gl-carousel-wrap.no-padding .owl-carousel.cc-height-full, 
	.gl-carousel-wrap.no-padding .owl-carousel.cc-height-full .cc-item {
		height: calc(100vh - 82px);
	}

	body.footer-minimal-on .gl-carousel-wrap.no-padding .owl-carousel.cc-height-full, 
	body.footer-minimal-on .gl-carousel-wrap.no-padding .owl-carousel.cc-height-full .cc-item {
		height: calc(100vh - 162px);
	}
}
@media (max-width: 991px) {
	.gl-carousel-wrap.no-padding .owl-carousel.cc-height-full, 
	.gl-carousel-wrap.no-padding .owl-carousel.cc-height-full .cc-item {
		height: calc(100vh - 62px);
	}
}

/* if header-transparent enabled */
body.header-transparent-on .gallery-list-carousel .owl-carousel.cc-height-full, 
body.header-transparent-on .gallery-list-carousel .owl-carousel.cc-height-full .cc-item {
	height: calc(100vh - 82px);
}
body.header-transparent-on .gl-carousel-wrap.no-padding .owl-carousel.cc-height-full, 
body.header-transparent-on .gl-carousel-wrap.no-padding .owl-carousel.cc-height-full .cc-item {
	height: 100vh;
}
@media (max-width: 991px) {
	body.header-transparent-on .gl-carousel-wrap.no-padding .owl-carousel.cc-height-full, 
	body.header-transparent-on .gl-carousel-wrap.no-padding .owl-carousel.cc-height-full .cc-item {
		height: calc(100vh - 62px); /* offset = same as mobile header heigt */
	}
}


/* Gallery list item inside "cc-item" (in case you want to use it) 
====================================== */
.gallery-list-carousel .gallery-list-item,
.gallery-list-carousel .gallery-list-item .gl-item-image {
	height: 100%;
}
.gallery-list-carousel .gl-item-info {
	position: absolute;
	left: 0;
	bottom: 0;
}
.gallery-list-carousel .gli-alter-3 .gl-item-info {
	top: 10%;
	bottom: 10%;
	left: 10%;
	right: 10%;
}
.gallery-list-carousel .gli-alter-2 .gl-item-title {
   font-size: 22px;
}


/* If thumb-list inside gallery list carousel
============================================== */
.gallery-list-carousel .thumb-list {
	position: absolute;
	top: 50%;
	right: 9%;
	width: 40%;
	padding: 40px;
	z-index: 2;
	opacity: 0;
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);

	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
@media (max-width: 1024px) { /* disable thumb-list on smaller screens */
	.gallery-list-carousel .thumb-list {
		display: none;
	}
}
.gallery-list-carousel .owl-carousel .owl-item.active .thumb-list {
	right: 9%;
	opacity: 1;
	-webkit-transition-delay: 0.7s;
	-moz-transition-delay: 0.7s;
	transition-delay: 0.7s;
}
@media (max-width: 1400px) {
	.gallery-list-carousel .thumb-list, 
	.gallery-list-carousel .owl-carousel .owl-item.active .thumb-list{
		right: 5%;
	}
}

.gallery-list-carousel .thumb-list > li {
	padding: 1%;
}
.gallery-list-carousel .thumb-list .thumb-list-item {
	padding-bottom: 65%;
	border: 12px solid #fff;
}
@media (max-width: 1400px) {
	.gallery-list-carousel .thumb-list .thumb-list-item {
		border-width: 8px;
	}
}
.gallery-list-carousel .thumb-list .thumb-list-item:hover {
	opacity: 1;
	-webkit-transform: scale(1.08);
	-moz-transform: scale(1.08);
	-o-transform: scale(1.08);
	-ms-transform: scale(1.08);
	transform: scale(1.08);
	box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.5);
}


/* ------------------------------------------------------------- *
 * Gallery thumbnails section
/* ------------------------------------------------------------- */

#gallery-thumbnails-section {
	padding-top: 5%;
}

.gts-inner {
	padding: 0 3%;
}
.gts-inner.tt-wrap {
	padding: 0;
}

/* gallery thumbnails list block */
.gallery-thumbnails-block {
	margin-bottom: 70px;
}

/* gtb heading */
.gtb-heading {
	margin-bottom: 30px;
}
.gtb-heading-title {
	margin: 0;
	font-size: 28px;
}
.gtb-heading-category {
	margin-bottom: 15px;
	font-size: 12px;
	color: #999;
}
.gtb-heading-category a,
.gtb-heading-category span {
	background-color: #08c1b8;
	padding: 1px 5px 0px 5px;
	color: #FFF;
}
.gtb-heading-category a:hover {
	opacity: .8;
}
.gtb-heading-date {
	margin-top: 5px;
	font-size: 14px;
	color: #999;
}

/* gallery thumbnails list */
.gallery-thumbnails-list {
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 0;
}
.gallery-thumbnails-list > li {
	display: inline-block;
	width: 140px;
	vertical-align: top;
	font-size: inherit;
}
@media (max-width: 992px) {
	.gallery-thumbnails-list > li {
		width: 100px;
	}
}
@media (max-width: 768px) {
	.gallery-thumbnails-list > li {
		width: 80px;
	}
}

/* gallery thumbnails list item */
.gtl-item-link {
}
.gtl-item {
	background-color: rgba(138, 138, 138, 0.13);
	padding-bottom: 80%;
	margin: 3px;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.gtl-item:hover {
	opacity: .85;
}


/* ------------------------------------------------------------- *
 * Gallery single section
/* ------------------------------------------------------------- */

#gallery-single-section {
	width: 100%;
}

/* isotope items in gallery single section */
@media (max-width: 768px) {
	#gallery-single-section .isotope.col-2 .isotope-item,
	#gallery-single-section .isotope.col-3 .isotope-item,
	#gallery-single-section .isotope.col-4 .isotope-item,
	#gallery-single-section .isotope.col-5 .isotope-item,
	#gallery-single-section .isotope.col-6 .isotope-item {
		width: 50%;
	}
}

.gallery-single-inner {
	position: relative;
}


/* gallery single item 
======================= */
.gallery-single-item { 
	position: relative;
	display: block;
	overflow: hidden;
	background-color: rgba(138, 138, 138, 0.11);
}

/* gallery single item image */
.gallery-single-item .gs-item-image {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.gallery-single-item:hover .gs-item-image,
.iso-height-1 .gallery-single-item:hover .gs-item-image, 
.iso-height-2 .gallery-single-item:hover .gs-item-image {
	-webkit-transform: scale(1.08);
	-moz-transform: scale(1.08);
	-o-transform: scale(1.08);
	-ms-transform: scale(1.08);
	transform: scale(1.08);
}

.iso-height-1 .gallery-single-item .gs-item-image,
.iso-height-2 .gallery-single-item .gs-item-image {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.gallery-single-item img {
}

/* gallery single item cover */
.gallery-single-item:before {
	position: absolute;
	content: "";
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000;
	z-index: 2;
	opacity: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.gallery-single-item:hover:before {
	opacity: .5;
}

/* gallery single item info */
.gallery-single-item .gsi-image-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0 80px 0 8%;
	z-index: 9;
	font-size: 16px;
	color: #FFF;
	opacity: 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.gallery-single-item:hover .gsi-image-caption {
	bottom: 30px;
	opacity: 1;

	-webkit-transition-delay: 0.5s;
	-moz-transition-delay: 0.5s;
	transition-delay: 0.5s;
}

/* gallery single item icon */
.gallery-single-item .gs-item-icon {
	position: absolute;
	display: block;
	right: 35px;
	bottom: 10px;
	font-size: 22px;
	color: #FFF;
	opacity: 0;
	z-index: 2;

	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.gallery-single-item:hover .gs-item-icon {
	opacity: 1;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);

	-webkit-transition-delay: 0.3s;
	-moz-transition-delay: 0.3s;
	transition-delay: 0.3s;
}


/* gallery single item styles 
============================== */

/* gsi-color */
#gallery.gsi-color .gallery-single-item:before {
	background-color: #08c1b8;
}
#gallery.gsi-color .gallery-single-item:hover:before {
	opacity: .65;
}
#gallery.gsi-color .gs-item-info .gs-item-icon {
	color: #FFF;
}

/* gsi-zoom */
#gallery.gsi-zoom .gsi-image-caption,
#gallery.gsi-zoom .gs-item-icon,
#gallery.gsi-zoom .gallery-single-item:before {
	display: none;
}

/* gsi-simple */
#gallery.gsi-simple .gsi-image-caption,
#gallery.gsi-simple .gs-item-icon,
#gallery.gsi-simple .gallery-single-item:before {
	display: none;
}
#gallery.gsi-simple .iso-height-1 .gallery-single-item:hover .gs-item-image, 
#gallery.gsi-simple .iso-height-2 .gallery-single-item:hover .gs-item-image {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}
#gallery.gsi-simple .gallery-single-item {
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
#gallery.gsi-simple .gallery-single-item:hover {
	opacity: .8;
}


/* gallery single share
======================== */
.gallery-share {
	position: absolute;
	left: 3%;
	top: 50px;
	width: 55px;
	height: 55px;
	line-height: 57px;
	background-color: #08c1b8;
	text-align: center;
	font-size: 18px;
	color: #FFF;
	cursor: pointer;
	z-index: 9;
	border-radius: 60px;
	-webkit-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.25);
	box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.25);

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.gallery-share:hover,
.gallery-share:focus {
	color: #FFF;
}
.gallery-share:hover {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.isotope.gutter-1 .gallery-share {
	top: 60px;
}
.isotope.gutter-2 .gallery-share {
	top: 80px;
}
.isotope.gutter-3 .gallery-share {
	top: 100px;
}

/* gallery single share align right */
.gallery-share.gs-right {
	left: auto;
	right: 3%;
}

/* if ".isotope-top-content" contains class "fi-to-button-on" */
.isotope-top-content.gallery-share-on {
	margin-top: 0 !important;
	padding: 0;
}

.isotope.gutter-1 .isotope-top-content.gallery-share-on {
	padding-top: 6px;
	padding-bottom: 0;
}
.isotope.gutter-2 .isotope-top-content.gallery-share-on {
	padding-top: 1.5%;
	padding-bottom: 0;
}
.isotope.gutter-3 .isotope-top-content.gallery-share-on {
	padding-top: 2.5%;
	padding-bottom: 0;
}

/* if page header enabled */
body.page-header-on .gallery-share,
body.page-header-on .isotope.gutter-1 .gallery-share,
body.page-header-on .isotope.gutter-2 .gallery-share,
body.page-header-on .isotope.gutter-3 .gallery-share {
	top: -28px !important;
}

/* if page boxed layout enabled */
body.tt-boxed .isotope-wrap.tt-wrap .gallery-share {
	left: 0;
	margin: 0;
}
body.tt-boxed .isotope-wrap.tt-wrap .gallery-share.gs-right {
	left: auto;
	right: 0;
}

@media (min-width: 768px) {
	body.tt-boxed .isotope-wrap.tt-wrap .isotope-top-content.gallery-share-on,
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-1 .isotope-top-content.gallery-share-on,
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-2 .isotope-top-content.gallery-share-on,
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-3 .isotope-top-content.gallery-share-on {
		padding-top: 50px;
	}
}

body.tt-boxed .isotope-wrap.tt-wrap .gallery-share,
body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-1 .gallery-share,
body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-2 .gallery-share,
body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-3 .gallery-share {
	top: 20px;
}

body.tt-boxed.page-header-on .isotope-wrap.tt-wrap .isotope-top-content.gallery-share-on {
	padding-top: 40px;
	padding-bottom: 0;
}
body.tt-boxed.page-header-on .isotope-wrap.tt-wrap .isotope.gutter-1 .isotope-top-content.gallery-share-on {
	padding-top: 40px;
	padding-bottom: 0;
}
body.tt-boxed.page-header-on .isotope-wrap.tt-wrap .isotope.gutter-2 .isotope-top-content.gallery-share-on {
	padding-top: 40px;
	padding-bottom: 0;
}
body.tt-boxed.page-header-on .isotope-wrap.tt-wrap .isotope.gutter-3 .isotope-top-content.gallery-share-on {
	padding-top: 40px;
	padding-bottom: 0;
}


/* move gallery share button to bottom on small screens 
======================================================== */
@media (max-width: 768px) {
	.isotope-filter.fi-to-button,

	body.page-header-on .gallery-share, 
	body.page-header-on .isotope.gutter-1 .gallery-share, 
	body.page-header-on .isotope.gutter-2 .gallery-share, 
	body.page-header-on .isotope.gutter-3 .gallery-share,

	body.tt-boxed .isotope-wrap.tt-wrap .gallery-share, 
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-1 .gallery-share, 
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-2 .gallery-share, 
	body.tt-boxed .isotope-wrap.tt-wrap .isotope.gutter-3 .gallery-share {
		position: fixed;
		top: auto !important;
		bottom: 15px !important;
		left: auto !important;
		right: 15px !important;
	}
}


/* Gallery single meta
======================= */
ul.gallery-meta {
	display: inline-block;
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 18px;
	color: #a7a7a7;
}
ul.gallery-meta > li {
	float: left;
	margin-right: 12px;
}
ul.gallery-meta > li:last-child {
	margin-right: 0 !important;
}
ul.gallery-meta > li a {
	color: #a7a7a7;
}
ul.gallery-meta > li a:hover {
	color: #08c1b8;
}

/* content share trigger */
.content-share-trigger {
}
.content-share-trigger a {
}

/* content info trigger */
.content-info-trigger {
}
.content-info-trigger a {
}


/* gallery single simple item 
============================== */
.gs-simple-item {
	margin-bottom: 40px;
}
@media (max-width: 992px) {
	.gs-simple-item {
		margin-bottom: 20px;
	}
}

.gs-simple-item .gss-item-image {
	display: block;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.gs-simple-item .gss-item-image:hover {
	opacity: .9;
}

.gs-simple-item .gss-image-caption {
	margin-top: 7px;
	font-style: italic;
	font-size: 13px;
	color: #888;
}


/* Gallery single credits 
========================== */
.gallery-single-credits {
}
.gallery-single-credits dl {
	margin-bottom: 20px;
}
.gallery-single-credits dt {
	font-weight: bold;
	font-weight: 600;
}
.gallery-single-credits dd {
	font-weight: normal;
	font-size: 14px;
	color: #888
}


/* Gallery single nav 
====================== */
.gallery-single-nav {
}
.gallery-single-nav a {
	display: inline-block;
	width: 20px;
	height: 20px;
	line-height: 20px;
	color: #222;
	text-align: center;
}
.gallery-single-nav a:hover {
	opacity: .8;
}

.gallery-single-nav .gsn-prew {
	margin-right: 10px;
	font-size: 25px;
}
.gallery-single-nav .gsn-next {
	margin-left: 10px;
	font-size: 25px;
}
.gallery-single-nav .gsn-back {
	font-size: 20px;
}

/* if gallery single nav in page header */
#page-header .gallery-single-nav {
	position: absolute;
	right: 60px;
	bottom: 80px;
	z-index: 9;
}
@media (max-width: 768px) {
	#page-header .gallery-single-nav {
		bottom: 30px;
		right: 15px;
	}
}
#page-header .gallery-single-nav a {
	color: #222;
}
#page-header.ph-image-on .gallery-single-nav a {
	color: #FFF;
}

body.iso-tt-wrap-on #page-header .gallery-single-nav {
	right: 15px;
}


/* ------------------------------------------------------------- *
 * Gallery single carousel 
/* ------------------------------------------------------------- */

.gallery-single-carousel {
}

.gallery-single-carousel-inner {
}

.gs-carousel-wrap {
	position: relative;
	background-color: rgba(128, 128, 128, 0.12);
	padding: 40px 0;
}
@media (max-width: 992px) {
	.gs-carousel-wrap {
		padding: 15px;
	}
}

@media (min-width: 992px) {
	.gallery-single-carousel .owl-carousel .owl-stage-outer {
		margin-left: -1px;
		padding-left: 60px;
	}
	.gs-carousel-center .owl-carousel .owl-stage-outer {
		margin-left: 0;
		padding-left: 0;
	}
}


/* Gallery single full height carousel offsets 
=============================================== */
.gallery-single-carousel .owl-carousel.cc-height-full, 
.gallery-single-carousel .owl-carousel.cc-height-full .cc-item {
	height: calc(100vh - 162px);
}
@media (max-width: 991px) {
	.gallery-single-carousel .owl-carousel.cc-height-full, 
	.gallery-single-carousel .owl-carousel.cc-height-full .cc-item {
		height: calc(100vh - 92px);
	}
}

@media (min-width: 992px) {
	body.footer-minimal-on .gallery-single-carousel .owl-carousel.cc-height-full, 
	body.footer-minimal-on .gallery-single-carousel .owl-carousel.cc-height-full .cc-item {
		height: calc(100vh - 242px);
	}
}

/* if class "no-padding" enabled */
@media (min-width: 992px) {
	.gs-carousel-wrap.no-padding .owl-carousel.cc-height-full, 
	.gs-carousel-wrap.no-padding .owl-carousel.cc-height-full .cc-item {
		height: calc(100vh - 82px);
	}
	body.footer-minimal-on .gs-carousel-wrap.no-padding .owl-carousel.cc-height-full, 
	body.footer-minimal-on .gs-carousel-wrap.no-padding .owl-carousel.cc-height-full .cc-item {
		height: calc(100vh - 162px);
	}
}
@media (max-width: 991px) {
	.gs-carousel-wrap.no-padding .owl-carousel.cc-height-full, 
	.gs-carousel-wrap.no-padding .owl-carousel.cc-height-full .cc-item {
		height: calc(100vh - 62px);
	}
}

/* if header-transparent enabled */
body.header-transparent-on .gallery-single-carousel .owl-carousel.cc-height-full, 
body.header-transparent-on .gallery-single-carousel .owl-carousel.cc-height-full .cc-item {
	height: calc(100vh - 82px);
}
body.header-transparent-on .gs-carousel-wrap.no-padding .owl-carousel.cc-height-full, 
body.header-transparent-on .gs-carousel-wrap.no-padding .owl-carousel.cc-height-full .cc-item {
	height: 100vh;
}


/* Gallery single carousel item 
================================ */
.gallery-single-carousel .cc-item {
}

.gallery-single-carousel .cc-item.gs-carousel-item {
}

/* gallery single carousel item image caption */
.gallery-single-carousel .cc-item .gsi-image-caption {
	position: absolute;
	left: 5%;
	bottom: 3%;
	max-width: 600px;
	background-color: rgba(255, 255, 255, 0.85);
	margin-right: 10%;
	padding: 10px;
	font-size: 14px;
	font-style: italic;
	color: #222;
	z-index: 3;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.gallery-single-carousel .cc-item:hover .gsi-image-caption {
	bottom: 5%;
	opacity: 1;
	visibility: visible;

	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
@media (max-width: 992px) { /* disable captions on small screens */
	.gallery-single-carousel .cc-item .gsi-image-caption {
		display: none;
	}
}

/* video play icon for gallery single carousel item */
.gallery-single-carousel .cc-item.gs-carousel-item .video-play-icon:before {
	position: absolute;
	display: block;
	height: 70px;
	width: 70px;
	line-height: 70px;
	left: 50%;
	top: 50%;
	margin-left: -35px;
	margin-top: -35px;
	background-color: rgba(0, 0, 0, 0.7);
	padding-left: 5px;
	text-align: center;
	font-size: 24px;
	color: #FFF;
	cursor: pointer;
	opacity: 1;
	z-index: 1;
	border-radius: 50%;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;

	font-family: "Font Awesome 5 Free";
	content: "\f04b";
	font-weight: 900;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}
.gallery-single-carousel .cc-item.gs-carousel-item:hover .video-play-icon:before {
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-o-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}


/* gallery single carousel meta 
================================ */
.gallery-single-carousel ul.gallery-meta {
	position: absolute;
	right: 27px;
	bottom: 60px;
	background-color: rgba(255, 255, 255, 0.9);
	padding: 10px 20px 8px 20px;
	z-index: 2;
	color: #333;
	/* border-radius: 50px; */
}
@media (max-width: 992px) {
	.gallery-single-carousel ul.gallery-meta {
		right: 30px;
		bottom: 30px;
	}
}

.gallery-single-carousel ul.gallery-meta > li {
	margin-right: 20px;
}
.gallery-single-carousel ul.gallery-meta > li a {
	color: #333;
}
.gallery-single-carousel ul.gallery-meta > li a:hover {
	color: #08c1b8;
}
.gallery-single-carousel ul.gallery-meta .favorite-btn {
	font-size: 16px;
}


/* gallery single carousel nav-bottom-right
============================================ */
.gallery-single-carousel .nav-bottom-right .owl-nav {
	position: absolute;
	top: auto;
	bottom: 150px;
	right: 35px;
	width: 133px;
}
@media (max-width: 992px) {
	.gallery-single-carousel .nav-bottom-right .owl-nav {
		bottom: 135px;
		right: 20px;
	}
}


/* gallery single carousel center 
================================== */

/* gallery single carousel item cover (for carousel center) */
@media (min-width: 992px) {
	.gallery-single-carousel.gs-carousel-center .cc-item .gsi-image-caption {
		opacity: 0;
		visibility: hidden;
	}
	.gallery-single-carousel.gs-carousel-center .owl-item.center .cc-item .gsi-image-caption {
		bottom: 5%;
		opacity: 1;
		visibility: visible;
		-webkit-transition-delay: 0.5s;
		-moz-transition-delay: 0.5s;
		transition-delay: 0.5s;
	}
}

/* deactivate inactive slides. */
.gs-carousel-center .owl-item:before {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9;
}
.gs-carousel-center .owl-item.center:before {
	display: none;
}


/* Gallery single carousel info 
================================ */
.gs-carousel-info {
	position: relative;
	width: inherit; /* width handled by "theme.js" file */
	background-color: #FFF;
	padding: 8% 8% 80px 8%;
}

/* gallery single full height carousel info offsets */
.gallery-single-carousel .owl-carousel.cc-height-full .gs-carousel-info {
	height: calc(100vh - 162px);
}
@media (max-width: 991px) {
	.gallery-single-carousel .owl-carousel.cc-height-full .gs-carousel-info {
		height: calc(100vh - 112px);
	}
}
@media (max-width: 767px) {
	.gallery-single-carousel .owl-carousel.cc-height-full .gs-carousel-info {
		height: calc(100vh - 92px);
	}
}

@media (min-width: 992px) {
	body.footer-minimal-on .gallery-single-carousel .owl-carousel.cc-height-full .gs-carousel-info {
		height: calc(100vh - 242px);
	}
}

/* if class "no-padding" enabled on "gs-carousel-wrap" */
@media (min-width: 992px) {
	.gs-carousel-wrap.no-padding .owl-carousel.cc-height-full .gs-carousel-info {
		height: calc(100vh - 162px);
	}
}
@media (max-width: 991px) {
	.gs-carousel-wrap.no-padding .owl-carousel.cc-height-full .gs-carousel-info {
		height: calc(100vh - 62px);
	}
}

/* callery single carousel info inner */
.gsc-info-inner {
	height: 100%;
	overflow-y: auto;
}

.gs-carousel-info .gallery-single-nav {
	position: absolute;
	left: 8%;
	bottom: 25px;
	margin: 0;
}
.gallery-single-carousel .gs-carousel-info ul.gallery-meta {
	position: absolute;
	right: 8%;
	bottom: 25px;
	padding: 0;
	background-color: transparent;
	z-index: 2;
	font-size: 18px;
	color: #a7a7a7;
}
.gallery-single-carousel .gs-carousel-info ul.gallery-meta .favorite-btn {
	font-size: 14px;
}


/* ------------------------------------------------------------- *
 * Gallery single section with sidebar
/* ------------------------------------------------------------- */

.gallery-single-with-sidebar {
	padding-top: 20px;
	padding-left: 3%;
	padding-right: 3%;
}
@media (max-width: 1600px) {
	.gallery-single-with-sidebar {
		padding-left: 15px;
		padding-right: 15px;
	}
	body.tt-boxed .gallery-single-with-sidebar .gallery-single-inner.tt-wrap {
		padding: 0;
	}
}

/* If header-transparent enabled */
body.header-transparent-on .gallery-single-with-sidebar {
		padding-top: 90px;
	}
@media (max-width: 1200px) {
	body.header-transparent-on .gallery-single-with-sidebar {
		padding-top: 90px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

/* If page header enabled */
body.page-header-on .gallery-single-with-sidebar {
	padding-top: 40px;
}
@media (max-width: 1200px) {
	body.page-header-on .gallery-single-with-sidebar {
		padding-top: 20px;
	}
}
body.page-header-on .gallery-single-with-sidebar .gallery-single-inner.tt-wrap {
	padding-top: 0;
}


/* Gallery single sidebar
========================== */
.gs-sidebar {
	position: relative;
	margin-bottom: 30px;
}

.gs-sidebar-left .gs-sidebar {
	padding: 0 10px 0 0;
}
.gs-sidebar-right .gs-sidebar {
	padding: 0 0 0 10px;
}
@media (max-width: 1200px) {
	.gs-sidebar-left .gs-sidebar,
	.gs-sidebar-right .gs-sidebar {
		padding: 0;
	}
}

/* gallery single sidebar info */
.gs-sidebar-info {
	padding: 30px;
	border: 1px solid rgba(138, 138, 138, 0.16);
}
@media (max-width: 992px) {
	.gs-sidebar-info {
		padding: 20px;
	}
}

/* gallery single sidebar title */
.gs-sidebar-title {
	margin-bottom: 30px;
	font-size: 34px;
}

/* gallery single sidebar info description */
.gs-sidebar-description {
	margin-top: 30px;
}

/* gallery single sidebar meta */
.gs-sidebar ul.gallery-meta {
	position: absolute;
	top: 15px;
	right: 30px;
}
@media (max-width: 992px) {
	.gs-sidebar ul.gallery-meta {
		top: 10px;
		right: 15px;
	}
}


/* if isotope gutters enabled 
============================== */
.gallery-single-with-sidebar .isotope.gutter-1 .isotope-items-wrap,
.gallery-single-with-sidebar .isotope.gutter-2 .isotope-items-wrap,
.gallery-single-with-sidebar .isotope.gutter-3 .isotope-items-wrap {
	padding-top: 0;
	margin-top: 0 !important;
	margin-left: 0;
	margin-right: 0;
}

.col-3 {
  max-width: 100%;
}

.grid-item {
  margin-bottom: 0!important;
}